import React, { useCallback, useState } from 'react';
import {
  PressableProps as RNPPressableProps,
  Pressable as RNPressable,
} from 'react-native';

export interface PressableRenderProps {
  pressed: boolean;
  hovered: boolean;
}

export interface PressableProps extends Omit<RNPPressableProps, 'children'> {
  children: (props: PressableRenderProps) => React.ReactNode;
}

export const Pressable = ({ children, ...rest }: PressableProps) => {
  const [hovered, setHovered] = useState(false);
  const onHoverIn = useCallback(() => setHovered(true), [setHovered]);
  const onHoverOut = useCallback(() => setHovered(false), [setHovered]);

  return (
    <RNPressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} {...rest}>
      {({ pressed }) => children({ pressed, hovered })}
    </RNPressable>
  );
};
