import { useInterval } from '@mantine/hooks';
import { FormInstance, ModalFuncProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import dayjs from 'dayjs';
import { LocationDescriptor } from 'history';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MutateOptions } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { OrgFeatureFlag } from 'xo/constants';
import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';
import { ApiSiteSummaryFragment } from 'xo/graphql/api/site-summary-fragment.generated';
import {
  useFlag as useSharedFlag,
  useTimedFlag,
} from 'xo/hooks/component-hooks';
import { useModelMap } from 'xo/hooks/model-hooks';
import {
  useCurrentUser,
  useOptionalActualCurrentUser,
  useSites,
  useTransportSites,
} from 'xo/login/current-user-hooks';
import { screens } from 'xo/styles/tailwind-theme';
import { IdOnly, Module } from '../api-models';
import { confirm } from '../app/components/modal';
import { SITE_FEATURES } from '../app/people-office/visitor-log-constants';

// A toggleable boolean flag
export const useFlagToggle = (initial: boolean): [boolean, () => void] => {
  const [flag, setFlag] = useState(initial);

  const toggleFlag = useCallback(() => {
    setFlag(flag => !flag);
  }, [setFlag]);

  return [flag, toggleFlag];
};

// A flag with true/false setters
export const useFlag = useSharedFlag;

export const useSet = <T>(
  init?: T[],
): [
  Set<T>,
  {
    add: (item: T) => void;
    remove: (item: T) => void;
    toggle: (item: T) => void;
    clear: () => void;
    update: (items: Set<T>) => void;
  },
] => {
  const [set, updateSet] = useState(new Set<T>(init));
  const add = useCallback(
    (item: T) => {
      updateSet(currentSet => new Set(currentSet).add(item));
    },
    [updateSet],
  );
  const remove = useCallback(
    (item: T) => {
      updateSet(currentSet => {
        const newSet = new Set(currentSet);
        newSet.delete(item);
        return newSet;
      });
    },
    [updateSet],
  );
  const toggle = useCallback(
    (item: T) => {
      if (set.has(item)) {
        remove(item);
      } else {
        add(item);
      }
    },
    [add, remove, set],
  );
  const clear = useCallback(() => {
    updateSet(currentSet => {
      const newSet = new Set(currentSet);
      newSet.clear();
      return newSet;
    });
  }, [updateSet]);
  const update = useCallback(
    (items: Set<T>) => {
      updateSet(items);
    },
    [updateSet],
  );
  return [set, { add, remove, toggle, clear, update }];
};

export const useNestedRoute = () => {
  const match = useRouteMatch();
  return useCallback((path: string) => `${match.url}/${path}`, [match]);
};

export interface UseFormSubmitProps<
  TForm,
  TRequest,
  TResponse,
  TError,
  TContext,
> {
  form: FormInstance<TForm>;
  confirmProps?: ModalFuncProps | ((values: TForm) => ModalFuncProps | null);
  onSubmit:
    | ((
        values: TForm,
        opts: MutateOptions<TResponse, TError, TRequest, TContext>,
      ) => void)
    | ((
        values: TForm,
        opts: MutateOptions<TResponse, TError, TRequest, TContext>,
      ) => Promise<TResponse | undefined>);
  onSuccess?: MutateOptions<TResponse, TError, TRequest, TContext>['onSuccess'];
  onSettled?: MutateOptions<TResponse, TError, TRequest, TContext>['onSettled'];
  onError?: MutateOptions<TResponse, TError, TRequest, TContext>['onError'];
}

export const useFormSubmit = <TForm, TRequest, TResponse, TError, TContext>({
  form,
  confirmProps,
  onSubmit,
  onSuccess,
  onSettled,
  onError,
}: UseFormSubmitProps<TForm, TRequest, TResponse, TError, TContext>) => {
  const [error, onFlashError] = useTimedFlag(400);
  const [loading, onSetLoading, onUnsetLoading] = useFlag(false);
  return {
    error,
    loading,
    onSubmit: useCallback(async () => {
      try {
        // Scroll to field handled by forms onFinishFailed
        form.submit();
        // Throws on erroring fields
        await form.validateFields();
      } catch (err) {
        onFlashError();
        throw err;
      }

      const formValues = form.getFieldsValue(true);

      const onOk = async () => {
        onSetLoading();

        const reactQueryOpts: MutateOptions<
          TResponse,
          TError,
          TRequest,
          TContext
        > = {
          onSuccess: (
            data: TResponse,
            variables: TRequest,
            context: TContext,
          ) => {
            onUnsetLoading();
            onSuccess && onSuccess(data, variables, context);
          },
          onError: (error, variables, context) => {
            onUnsetLoading();
            onError && onError(error, variables, context);
          },
          onSettled,
        };

        const submitResult = onSubmit(formValues, reactQueryOpts) as
          | Promise<TResponse>
          | undefined;
        if (submitResult?.then) {
          const r = await submitResult;
          // Support urql mutations and React Query mutateAsync
          const error = (r as any)?.error;
          if (error) {
            onError && onError(error, {} as any, {} as any);
          } else {
            onSuccess && onSuccess(r, {} as any, {} as any);
          }
          onUnsetLoading();
        }
      };

      const resolvedConfirmProps =
        typeof confirmProps === 'function'
          ? confirmProps(formValues)
          : confirmProps;

      if (resolvedConfirmProps) {
        return new Promise(resolve => {
          confirm({
            ...resolvedConfirmProps,
            onOk: () => {
              resolvedConfirmProps.onOk && resolvedConfirmProps.onOk();
              resolve(onOk());
            },
          });
        });
      } else {
        return onOk();
      }
    }, [
      form,
      onFlashError,
      onSetLoading,
      onUnsetLoading,
      onSubmit,
      onSuccess,
      onSettled,
      onError,
      confirmProps,
    ]),
  };
};

// run a promise-based function in a timeout
const promiseTimeout = (cb: () => Promise<any>) =>
  new Promise<void>(resolve =>
    setTimeout(async () => {
      await cb();
      resolve();
    }),
  );

export const useFormValidate = <T>() => {
  const [validationError, onFlashError] = useTimedFlag(400);
  return {
    validationError,
    onValidate: useCallback(
      async (
        form: FormInstance<T>,
        opts: { nameList?: NamePath[]; scroll?: boolean } = { scroll: true },
      ) => {
        try {
          // Scroll to field handled by forms onFinishFailed
          if (opts?.scroll) {
            form.submit();
          }

          // run in a timeout, otherwise rc-field-form might mark it as outOfDate and fail validation
          // https://github.com/ant-design/ant-design/issues/26747#issuecomment-692553855
          // observed as problematic in the forgot password form submission with a mobile number
          await promiseTimeout(() => form.validateFields(opts?.nameList));

          return true;
        } catch (err) {
          onFlashError();
          return false;
        }
      },
      [onFlashError],
    ),
  };
};

export const useOnConfirmExit = ({
  content,
  onOk,
}: {
  content: string;
  onOk: () => void;
}) =>
  useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content,
      okText: 'Yes',
      cancelText: 'No',
      onOk,
    });
  }, [content, onOk]);

export const useHistoryPush = (route: LocationDescriptor) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(route);
  }, [history, route]);
};

export const useScrollToTopOnLocationChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const useScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export type OnFormChange = (
  _: any,
  { changedFields }: { changedFields: { name: NamePath; value?: any }[] },
) => void;

export const onStopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const useSiteHostMap = <T extends { hostAtSites: IdOnly[] }>(
  sites: { id: string }[],
  hosts: T[],
) =>
  useMemo(
    () =>
      new Map(
        sites.map(s => [
          s.id,
          hosts.filter(h => h.hostAtSites.some(h => h.id === s.id)),
        ]),
      ),
    [sites, hosts],
  );

export const useOnScroll = <T extends HTMLElement>(
  handler: (ref: MutableRefObject<T | null>) => void,
  selector?: string,
) => {
  const ref = useRef<T | null>(null);

  const onScroll = useCallback(() => {
    handler(ref);
  }, [handler, ref]);

  useLayoutEffect(() => {
    const element = selector
      ? ref.current?.querySelector(selector)
      : ref.current;
    if (element) {
      element.addEventListener('scroll', onScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll);
      }
    };
  }, [ref, onScroll, selector]);

  return ref;
};

export const useScrollFlags = <T extends HTMLElement>(selector?: string) => {
  const [horizontal, setHorizontal] = useState(false);
  const [vertical, setVertical] = useState(false);
  const handler = useCallback(
    (ref: React.MutableRefObject<T | null>) => {
      if (ref.current) {
        const element = selector
          ? ref.current.querySelector(selector)
          : ref.current;
        if (element) {
          const left = element.scrollLeft > 0;
          const top = element.scrollTop > 0;

          if (left !== horizontal) {
            setHorizontal(left);
          }

          if (top !== vertical) {
            setVertical(top);
          }
        }
      }
    },
    [horizontal, setHorizontal, vertical, setVertical, selector],
  );
  const ref = useOnScroll<T>(handler, '.ant-table-body');

  return { ref, horizontal, vertical };
};

export type ScreenSize = '2xl' | 'xl' | 'lg' | 'md' | 'sm';
export const useScreenSize = (): ScreenSize => {
  const twoXl = useMediaQuery({ minWidth: screens['2xl'] });
  const xl = useMediaQuery({ minWidth: screens.xl });
  const lg = useMediaQuery({ minWidth: screens.lg });
  const md = useMediaQuery({ minWidth: screens.md });

  return twoXl ? '2xl' : xl ? 'xl' : lg ? 'lg' : md ? 'md' : 'sm';
};

export const useShowCovidVaxSighted = () => {
  const sites = useSites();

  return useMemo(
    () =>
      sites.some(
        s =>
          s.features.includes(SITE_FEATURES.covidVaccinationDelivery) ||
          s.features.includes(SITE_FEATURES.covidVaccinationVisitor),
      ),
    [sites],
  );
};

// FIXME Update imports and remove
export { useModelMap };

export const useModelNameMap = <T extends { id: string }>(
  items: T[] | undefined,
  nameKey?: keyof T,
  key?: keyof T,
) =>
  useMemo(
    () =>
      new Map(
        items?.map(i => [
          i[key ?? 'id'] as string,
          (i as any)[nameKey ?? 'name'],
        ]),
      ),
    [items, key, nameKey],
  );

export const useSiteMap = () => {
  const sites = useSites();
  return useModelMap(sites);
};

export const useSiteShortCodeMap = () => {
  const sites = useSites();
  return useMemo(() => new Map(sites.map(s => [s.shortCode, s])), [sites]);
};

export const useSiteAirtableIdMap = () => {
  const transportSites = useTransportSites();
  return useMemo(
    () =>
      new Map(
        transportSites.filter(s => s.airtableId).map(s => [s.airtableId!, s]),
      ),
    [transportSites],
  );
};

export const useSiteHasFeature = () => {
  const siteMap = useSiteMap();

  return useCallback(
    (siteId: string, feature: string): boolean => {
      const site = siteMap.get(siteId);

      if (!site) {
        console.warn(`site ${siteId} not found in store`);
        return false;
      }

      return site.features.includes(feature);
    },
    [siteMap],
  );
};

// https://usehooks.com/useDebounce/
export const useDebounce = (value: any, delayMs: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delayMs);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delayMs], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useIsXoAdmin = () => {
  const actualCurrentUser = useOptionalActualCurrentUser();
  return actualCurrentUser?.kind === ApiUserKind.XoAdmin;
};

export const useRequiresModulePermission = ({
  canAccess,
  requires,
}: {
  requires: Module;
  canAccess?: Module[];
}) => {
  const history = useHistory();
  const isXoAdmin = useIsXoAdmin();

  useEffect(() => {
    if (Array.isArray(canAccess) && !isXoAdmin) {
      if (!canAccess.includes(requires)) {
        history.replace('/');
      }
    }
  }, [requires, canAccess, history, isXoAdmin]);
};

export const usePolling = (func: () => any, intervalMs: number) => {
  const { start, stop } = useInterval(func, intervalMs);
  useEffect(() => {
    start();
    return stop;
  }, [start, stop]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lastPolledAt = useMemo(() => dayjs(), [start]);
  return { lastPolledAt };
};

export const useIsOrgFeatureEnabled = (flag: OrgFeatureFlag) =>
  !!useCurrentUser()?.organisation?.features.includes(flag);

export const useSiteFeatureMap = (
  sites: Array<ApiSiteSummaryFragment> | undefined,
  feature: string,
) => {
  const siteHasFeature = useSiteHasFeature();
  return new Map(sites?.map(s => [s.id, siteHasFeature(s.id, feature)]));
};
