import {
  DocumentNode,
  FieldDefinitionNode,
  Kind,
  OperationDefinitionNode,
} from 'graphql';
import { OperationName } from 'xo/graphql/constants/operation-names.generated';

export const getOperationName = (documentNode: DocumentNode) => {
  const opNode = documentNode.definitions.find(
    d => d.kind === Kind.OPERATION_DEFINITION,
  ) as OperationDefinitionNode | undefined;
  const operationName = opNode?.name?.value;
  if (!operationName) {
    throw new Error(`No operation name found for document: ${documentNode}`);
  }
  return operationName as OperationName;
};

export const getResolverName = (documentNode: DocumentNode) => {
  const definition = documentNode.definitions.find(
    d => d.kind === Kind.OPERATION_DEFINITION,
  ) as OperationDefinitionNode | undefined;

  const argumentNode = definition?.selectionSet.selections.find(
    s => (s as any).selectionSet,
  ) as FieldDefinitionNode | undefined;

  const resolverName = argumentNode?.name.value;
  if (!resolverName) {
    throw new Error(
      `no resolver name found for document:  ${getOperationName(documentNode)}`,
    );
  }

  return resolverName;
};

export const isSameOperation = (d1: DocumentNode, d2: DocumentNode) =>
  getOperationName(d1) === getOperationName(d2);

export const isSameResolver = (d1: DocumentNode, d2: DocumentNode) =>
  getResolverName(d1) === getResolverName(d2);

export const getQueryData = <TModel extends object>(data?: any) =>
  Object.entries(data ?? {})
    .filter(([key]) => key !== '__typename')
    .map(([, value]) => value)[0] as TModel | undefined;
