import { FormInstance, useForm } from 'antd/es/form/Form';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { validate } from 'uuid';
import { formatIsoDate } from 'xo/date-utils';
import { ApiTransportUserKind } from 'xo/graphql/api/enums/transport-user-kind.generated';
import { useGetUserQuery } from 'xo/graphql/api/get-user-query.generated';
import { ApiUpdateUserAdminInput } from 'xo/graphql/api/inputs/update-user-admin-input.generated';
import { useUpdateUserAdminMutation } from 'xo/graphql/api/update-user-admin-mutation.generated';
import { ApiUserFragment } from 'xo/graphql/api/user-fragment.generated';
import { useCurrentUser } from 'xo/login/current-user-hooks';
import { Header } from '../components/header';
import { Panel } from '../components/panel';
import { Select } from '../components/select';
import { SkeletonLoader } from '../components/skeleton-loader';
import { DateTimePicker } from '../forms/date-time-picker';
import { Form, FormItem } from '../forms/form';
import { AdminMfa } from './admin-mfa';
import { AdminTestNotification } from './admin-test-notification-modal';

type AdminFields = 'transportKinds' | 'onboardedAtDate';

interface AdminUserForm extends Pick<ApiUserFragment, AdminFields> {}

export const AdminUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [{ data, fetching: loading }] = useGetUserQuery({
    variables: { id: id! },
    pause: !validate(id),
  });
  const user = data?.user;
  const currentUser = useCurrentUser();

  const [{ data: savedUser, fetching: savingUser }, updateUser] =
    useUpdateUserAdminMutation();

  const [form] = useForm<AdminUserForm>();
  useEffect(() => {
    const u = savedUser?.updateUserAdmin ?? user!;
    if (u) {
      form.setFieldsValue({
        ...u,
        transportKinds: u.transportKinds ?? [],
        onboardedAtDate: u.onboardedAtDate,
      });
    }
  }, [savedUser, user, form]);

  const onUpdateUser = (
    form: FormInstance,
    getInput: (values: AdminUserForm) => Partial<ApiUpdateUserAdminInput>,
  ) => {
    const newUser = form.getFieldsValue() as AdminUserForm;
    updateUser({
      input: {
        userId: id,
        ...getInput(newUser),
      },
    });
  };

  const getTransportKindsInput = ({ transportKinds }: AdminUserForm) => ({
    transportKinds: transportKinds ?? [],
  });
  const getOnboardedAtDateInput = ({ onboardedAtDate }: AdminUserForm) => ({
    onboardedAtDate: onboardedAtDate ? formatIsoDate(onboardedAtDate) : null,
  });

  return (
    <Panel>
      <Header level={2} underline={true} className="mb-4">
        User admin
      </Header>

      {currentUser.id === user?.id && (
        <>
          <AdminMfa />
          <AdminTestNotification />
        </>
      )}

      <div className="mt-4">
        <SkeletonLoader loading={loading}>
          {user && (
            <Form form={form}>
              {form => (
                <>
                  <FormItem
                    name="transportKinds"
                    label="Transport user types"
                    tooltip="These determine which filters the user has access to on the Trips page in Transport, and how the trips are displayed"
                  >
                    <Select
                      disabled={savingUser}
                      onBlur={() => onUpdateUser(form, getTransportKindsInput)}
                      onDeselect={() =>
                        onUpdateUser(form, getTransportKindsInput)
                      }
                      options={Object.values(ApiTransportUserKind).map(o => ({
                        label: capitalize(o),
                        value: o,
                      }))}
                      multi={true}
                      placeholder="Producer"
                    />
                  </FormItem>
                  <FormItem
                    name="onboardedAtDate"
                    label="Onboarded date"
                    tooltip="The date on which this person was onboarded in a live session"
                  >
                    <DateTimePicker
                      picker="date"
                      onChange={() =>
                        onUpdateUser(form, getOnboardedAtDateInput)
                      }
                      allowClear
                    />
                  </FormItem>
                </>
              )}
            </Form>
          )}
        </SkeletonLoader>
      </div>
    </Panel>
  );
};
