import { useContext } from 'react';
import { RouteProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useIsLoggedIn } from 'xo/login/auth-status';
import { appRoutes } from 'xo/navigation/web-routes';
import { SentryRoute } from '../../utils/sentry';
import { errorPageTypes } from './error-page';
import { ErrorPageContext } from './error-page-provider';

export interface LoggedInRouteProps extends RouteProps {
  redirect?: boolean;
}

export const LoggedInRoute = ({
  redirect = true,
  ...rest
}: LoggedInRouteProps) => {
  const isLoggedIn = useIsLoggedIn();
  const { onErrorPage } = useContext(ErrorPageContext);
  const history = useHistory();

  if (!isLoggedIn) {
    if (!redirect) {
      onErrorPage({
        path: window.location.pathname,
        props: errorPageTypes.NOT_FOUND_PAGE!,
      });
    } else {
      history.replace(appRoutes.account.login);
    }

    return null;
  }

  return <SentryRoute {...rest} />;
};
