import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

export interface LinkProps {
  className?: string;
  to?: LocationDescriptor;
  externalHref?: string;
  onClick?: (e: React.MouseEvent) => void;
  type?: 'success' | 'error' | 'info' | 'custom';
  bold?: boolean;
  children?: React.ReactNode;
}

export const Link: React.FC<LinkProps> = ({
  to,
  externalHref,
  onClick,
  className,
  type = 'info',
  bold = true,
  children,
  ...rest
}) => {
  const elem = externalHref ? (
    <a href={externalHref} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : to ? (
    <HashLink to={to} onClick={onClick} />
  ) : (
    <button type="button" onClick={onClick} />
  );

  return React.cloneElement(elem, {
    className: classNames(
      className,
      'underline transition-colors duration-200 ease-in',
      {
        'font-semibold': bold,
        'text-blue-400 hover:text-blue-300': type === 'info',
        'text-red-800 hover:text-red-400': type === 'error',
        'text-green-700 hover:text-green-400': type === 'success',
      },
    ),
    children,
    ...rest,
  });
};
