import classNames from 'classnames';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';

export interface IconTextProps {
  className?: string;
  icon: JSX.Element;
  wrap?: boolean;
  extra?: JSX.Element | string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
}

export const IconText: React.FC<IconTextProps> = ({
  className,
  icon,
  children,
  wrap,
  extra,
  size = 'lg',
  ...rest
}) => {
  const iconSize = {
    xs: '12px',
    sm: '18px',
    md: '24px',
    lg: '24px',
    xl: '32px',
  }[size];

  const iconScale = {
    xs: 0.5,
    sm: 0.8,
    md: 1,
    lg: 1,
    xl: 1.3,
  }[size];

  return (
    <div className={classNames({ truncate: !wrap })}>
      <div
        className={classNames('flex w-full items-center', className)}
        {...rest}
      >
        <div style={{ width: iconSize, height: iconSize }}>
          {React.cloneElement(icon, {
            fill: icon.props.fill ?? colors.black,
            scale: iconScale,
          })}
        </div>
        <div
          className={classNames('w-full', {
            'text-xl': size === 'xl',
            'text-lg': size === 'lg',
            'text-base': size === 'md',
            'text-sm': size === 'sm',
            'text-xs': size === 'xs',
            'ml-2': size !== 'xs',
            'ml-0.5': size === 'xs',
            truncate: !wrap,
          })}
        >
          {children}
        </div>
      </div>
      {extra && (
        <div
          className={classNames({
            'ml-8': size !== 'xl',
            'ml-10': size === 'xl',
          })}
        >
          {extra}
        </div>
      )}
    </div>
  );
};
