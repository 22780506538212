import { Collapse as AntCollapse } from 'antd';
import './collapse.overrides.css';
import { CollapseProps } from './collapse.props';

const KEY = 'key';

const Collapse = ({
  active,
  renderChildrenCollapsed = true,
  children,
}: CollapseProps) => (
  <AntCollapse
    ghost
    activeKey={active ? KEY : undefined}
    className="xo-ant-collapse"
  >
    <AntCollapse.Panel key={KEY} header="" showArrow={false}>
      {/* The forceRender prop on the panel doesn't appear to work. This has the disadvantage of immediately removing the content from the DOM before the animation has finished */}
      {renderChildrenCollapsed || active ? children : null}
    </AntCollapse.Panel>
  </AntCollapse>
);

export default Collapse;
