import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCaret } from 'xo/svg/svg-caret';
import { NavigationOption } from '../../hooks/use-navigation-options';

export interface NavBarItemProps {
  active: boolean;
  onClick: (item: NavigationOption) => void;
  item: NavigationOption;
}

export const NavBarItem: React.FC<NavBarItemProps> = ({
  item,
  active,
  onClick,
}) => {
  const onClickCb = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  const buttonOrLink = item.to ? <Link to={item.to} /> : <button />;

  return (
    <>
      {React.cloneElement(buttonOrLink, {
        className: classNames(
          'font-base relative block flex h-16 w-full items-center px-4 text-left text-xl leading-6 -tracking-0.25px text-white transition-colors duration-200 ease-out hover:bg-blue-300 hover:text-white',
          {
            'bg-blue-600': !active,
            'bg-blue-400': active,
          },
        ),
        onClick: onClickCb,
        children: (
          <>
            {React.cloneElement(item.icon, {
              fill: colors.white,
            })}
            <span className="ml-4">{item.label}</span>
            {item.nested && (
              <span className="absolute right-0 mr-4">
                <SvgCaret direction="r" fill={colors.white} />
              </span>
            )}
            {item.admin && (
              <div className="absolute right-0 mr-10 flex items-center justify-center rounded-lg bg-red-800 px-1 py-0.5 text-xs font-medium text-white">
                XO
              </div>
            )}
          </>
        ),
      })}
    </>
  );
};
