import { getAuthToken } from 'xo/auth-utils';
import getClientId from 'xo/client-id';
import {
  XO_ADMIN_ASSUME_USER_HEADER,
  XO_AUTHORISATION_HEADER,
  XO_CLIENT_COMMIT_HEADER,
  XO_CLIENT_CORRELATION_ID,
} from 'xo/constants';
import { ApiCurrentUserFragment } from 'xo/graphql/api/current-user-fragment.generated';
import { useOptionalCurrentUser } from 'xo/login/current-user-hooks';
import { Module, SiteSummaryModel } from '../api-models';
import { env } from '../app/env';
import { LocalStorage } from '../app/shared/local-storage';
import { getPermissions, isOrgAdmin } from '../utils/permissions';

export const getRequestHeaders = async (
  existingHeaders?: Record<string, string>,
) => {
  const headers = existingHeaders ?? {};
  if (!env.REACT_APP_PROXY_STAGE) {
    headers[XO_CLIENT_COMMIT_HEADER] = LocalStorage.commitBackend;
  }
  headers[XO_CLIENT_CORRELATION_ID] = getClientId();

  const token = await getAuthToken();
  if (token) {
    // custom header name to avoid conflicting with browser's
    // built-in basic Authorization on non-prod stacks
    headers[XO_AUTHORISATION_HEADER] = `Bearer ${token}`;

    // Set the assumed user header for admins
    const assumedUser = LocalStorage.getAssumedUser();
    if (headers[XO_ADMIN_ASSUME_USER_HEADER] === null) {
      // ... unless its already set to null, representing explicit disabling
      delete headers[XO_ADMIN_ASSUME_USER_HEADER];
    } else if (assumedUser?.userId) {
      headers[XO_ADMIN_ASSUME_USER_HEADER] = assumedUser.userId;
    }
  }

  return headers;
};

export interface UserModulePermissions {
  visitorPermission: boolean;
  transportSchedulingPermission: boolean;
  consignmentNotePermission: boolean;
  arrivalDashboardPermission: boolean;
}

// FIXME Remove this and update references
/**
 * @deprecated Use getPermissions from permissions.ts instead
 */
export const getUserModulePermissions = (
  user: ApiCurrentUserFragment | null,
): UserModulePermissions => {
  const { visitor, transport } = getPermissions(user);

  return {
    visitorPermission: !!visitor,
    transportSchedulingPermission: !!transport?.transportScheduling,
    consignmentNotePermission: !!transport?.consignmentNote,
    arrivalDashboardPermission: !!transport?.arrivalDashboard,
  };
};

export const useUserModulePermissions = () => {
  const user = useOptionalCurrentUser();
  return getUserModulePermissions(user);
};

export const getUserShouldNotSeeSchedule = (
  user: Pick<ApiCurrentUserFragment, 'hostAtSites' | 'roles'>,
) => {
  // the schedule view of entries doesn't currently work well for someone without sites, except org
  // admins, who can actually see all sites
  // FIXME: this should actually be keyed off the number of _visible_ sites
  return user.hostAtSites.length === 0 && !isOrgAdmin(user);
};

/**
 * @deprecated: this is incompatible with graphql API types, use `hasApiModuleAccess` from xo/login/permissions instead
 */
export const hasModuleAccess = (
  site: Pick<SiteSummaryModel, 'organisation' | 'modules'>,
  module: Module | undefined,
) =>
  !module ||
  (site.organisation.modules.includes(module) && site.modules.includes(module));

/**
 * @deprecated: this is incompatible with graphql API types, use `hasApiVisitorModuleAccess` from xo/login/permissions instead
 */
export const hasVisitorModuleAccess = (
  site: Pick<SiteSummaryModel, 'organisation' | 'modules'>,
) => hasModuleAccess(site, 'VISITOR');

/**
 * @deprecated: this is incompatible with graphql API types, use `hasApiTransportModuleAccess` from xo/login/permissions instead
 */
export const hasTransportModuleAccess = (
  site: Pick<SiteSummaryModel, 'organisation' | 'modules'>,
) => hasModuleAccess(site, 'TRANSPORT');
