export const compliantToPolicy = ({ internal }: { internal?: boolean }) =>
  `Compliant to ${internal ? 'internal ' : ''}policy`;
export const compliantToPolicyNights = (quarantineNights: number) =>
  quarantineNights === 0
    ? `No quarantine required`
    : `A ${quarantineNights} night gap observed`;
export const notCompliantToPolicy = ({ internal }: { internal?: boolean }) =>
  `Non-compliant to ${internal ? 'internal ' : ''}policy`;
export const notCompliantToPolicyNights = (quarantineNights: number) =>
  `A ${quarantineNights} night gap required`;

export const SITE_FEATURES = {
  covidVaccinationDelivery: 'COVID_VACCINATION_DELIVERY',
  covidVaccinationVisitor: 'COVID_VACCINATION_VISITOR',
  footbath: 'FOOTBATH',
  showerWhenEnteringPyramid: 'SHOWER_WHEN_ENTERING_PYRAMID',
  shower: 'SHOWER',
  qFeverVaccinationVisitor: 'QFEVER_VACCINATION_VISITOR',
  qFeverVaccinationDelivery: 'QFEVER_VACCINATION_DELIVERY',
  livestockDeliveryRiskAssessment: 'LIVESTOCK_DELIVERY_RISK_ASSESSMENT',
  chickenDeliveryRiskAssessment: 'CHICKEN_DELIVERY_RISK_ASSESSMENT',
  porkDeliveryRiskAssessment: 'PORK_DELIVERY_RISK_ASSESSMENT',
  feedMillDeliveryRiskAssessment: 'FEED_MILL_DELIVERY_RISK_ASSESSMENT',
  visitExplicitSignOut: 'VISIT_EXPLICIT_SIGN_OUT',
  visitQuestionAnimalDiseaseArea: 'VISIT_QUESTION_ANIMAL_DISEASE_AREA',
  visitQuestionChickenSalmonella: 'VISIT_QUESTION_CHICKEN_SALMONELLA',
  dairyFertiliserDeliveryRiskAssessment:
    'DAIRY_FERTILISER_DELIVERY_RISK_ASSESSMENT',
  visitQuestionDairyAnimalOwnership: 'VISIT_QUESTION_DAIRY_ANIMAL_OWNERSHIP',
  visitQuestionSiteInduction: 'VISIT_QUESTION_SITE_INDUCTION',
};

export type SiteFeatureMap = Record<string, boolean>;
