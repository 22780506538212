import { sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { ApiUserQueryKind } from 'xo/graphql/api/enums/user-query-kind.generated';
import { useGetUsersAdminMinimalQuery } from 'xo/graphql/api/get-users-admin-minimal-query.generated';
import { useInterfaceSettingsStore } from 'xo/store/interface-settings-store';
import { width } from 'xo/styles/tailwind-theme';
import { objectMatchesInput } from 'xo/utils/search-utils';
import { useModelMap, useScreenSize } from '../../hooks/shared-hooks';
import {
  clearAssumedUser,
  updateAssumedUser,
  useUserAssumptionTimeout,
} from '../admin/admin-assumption-utils';
import { Link } from '../components/link';
import { OptGroup, Option, Select } from '../components/select';

export interface AdminAssumerUserProps {
  clearLink?: boolean;
}

export const AdminAssumeUser: React.FC<AdminAssumerUserProps> = ({
  clearLink,
}) => {
  const userId = useUserAssumptionTimeout();

  const xoUIHidden = useInterfaceSettingsStore(state => state.xoUIHidden);

  const [{ data, fetching: usersLoading }] = useGetUsersAdminMinimalQuery({
    requestPolicy: 'cache-and-network',
    variables: { kind: ApiUserQueryKind.HostAndSupport },
  });
  const users = data?.admin.users;
  const userMap = useModelMap(users);

  const nonAdmins = [
    {
      label: 'Support users',
      users: sortBy(
        users?.filter(u => u.kind === 'XO_SUPPORT'),
        u => u.organisation?.name ?? u.id,
      ),
    },
    {
      label: 'Real people (use a support user if you can)',
      users: users?.filter(u => u.kind === 'NORMAL'),
    },
  ];

  const onFilterOption = useCallback(
    (input: string, option?: any) => {
      const user = userMap.get(option?.key);
      if (!user) return false;

      return objectMatchesInput(input, {
        id: user.id,
        name: user.name,
        organisation: user?.organisation?.name,
      });
    },
    [userMap],
  );

  const screenSize = useScreenSize();

  if (xoUIHidden) return null;

  return (
    <div className="flex items-center">
      <Select
        placeholder="Choose a host to assume"
        showSearch={true}
        filterOption={onFilterOption}
        onSelect={(id: string) =>
          updateAssumedUser({ id, name: userMap.get(id)?.name })
        }
        value={users && userId ? userId : undefined}
        allowClear={true}
        onClear={clearAssumedUser}
        size="middle"
        loading={usersLoading}
        dropdownMatchSelectWidth={false}
        style={{
          maxWidth: screenSize !== 'xl' ? width[40] : undefined,
          minWidth: width[40],
        }}
      >
        {nonAdmins.map(({ label, users }) => (
          <OptGroup key={label} label={label}>
            {(users ?? []).map(user => (
              <Option key={user.id} value={user.id}>
                {user.kind === 'XO_SUPPORT' ? (
                  <>
                    <span className="font-semibold">
                      {user?.organisation?.name}
                    </span>{' '}
                    (support user)
                  </>
                ) : (
                  <>
                    <span className="font-semibold">{user.name}</span>{' '}
                    <span>{user?.organisation?.name}</span>
                  </>
                )}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
      {userId && clearLink ? (
        <Link className="ml-2" onClick={() => clearAssumedUser()}>
          Clear
        </Link>
      ) : undefined}
    </div>
  );
};
