import { useCallback, useEffect } from 'react';
import { useGetCurrentUserQuery } from 'xo/graphql/api/get-current-user-query.generated';
import { useGetSitesQuery } from 'xo/graphql/api/get-sites.generated';
import SentryUtils from 'xo/utils/sentry-utils';
import { shallow } from 'zustand/shallow';
import { useIsLoggedIn } from './auth-status';
import { emitSignOut } from './use-signout-listener';
import { useUserStore } from './user-store';

export const useLoadCurrentUser = () => {
  const isLoggedIn = useIsLoggedIn();
  const [
    savedCurrentUser,
    setCurrentUser,
    setActualCurrentUser,
    setRefetchCurrentUser,
  ] = useUserStore(
    state => [
      state.currentUser,
      state.setCurrentUser,
      state.setActualCurrentUser,
      state.setRefetchCurrentUser,
    ],
    shallow,
  );

  useEffect(() => {
    // make sure we don't end up caching a user who isn't logged in
    if (!isLoggedIn) {
      setCurrentUser(null);
      setActualCurrentUser(null);
    }
  }, [isLoggedIn, setCurrentUser, setActualCurrentUser]);

  // An ExoFlare admin can assume the identity of another user, so we need to make two queries:
  // 1. The first query tells us who is actually logged in, whether XO admin or not
  // 2. The second query tells us who the admin has assumed the identity of, if applicable
  const [
    {
      data: actualUserData,
      fetching: fetchingActualUser,
      error: actualUserError,
    },
    refetchActualUser,
  ] = useGetCurrentUserQuery({
    requestPolicy: 'cache-and-network',
    pause: !isLoggedIn,
    variables: {
      input: {
        ignoreAssumption: true,
      },
    },
  });

  // if we're an XO admin assuming a user, fetch that assumed user
  const [
    {
      data: assumedUserData,
      fetching: fetchingAssumedUser,
      error: assumedUserError,
    },
    refetchAssumedUser,
  ] = useGetCurrentUserQuery({
    requestPolicy: 'cache-and-network',
    pause: !isLoggedIn,
    variables: {
      input: {
        ignoreAssumption: false,
      },
    },
  });

  // the current user is either the actual user or the assumed user so we need to make sure
  // neither are loading before resolving to one or the other
  const user =
    !fetchingActualUser && !fetchingAssumedUser
      ? assumedUserData?.me ?? actualUserData?.me
      : undefined;

  // update the current user any time it changes
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user, setCurrentUser]);

  useEffect(() => {
    if (actualUserData) {
      setActualCurrentUser(actualUserData.me);
      // use the actual user ID for Sentry
      SentryUtils.updateSentryUser({ id: actualUserData.me.id });
    }
  }, [actualUserData, setActualCurrentUser]);

  // if there's an error fetching the user, we don't want to stay logged in
  // for example, on mobile we could go offline while logging in before successfully fetching the user
  // so just sign out if this happens and the user can try again
  useEffect(() => {
    if (
      (actualUserError || assumedUserError) &&
      !savedCurrentUser &&
      isLoggedIn
    ) {
      emitSignOut();
    }
  }, [actualUserError, assumedUserError, savedCurrentUser, isLoggedIn]);

  const onRefetch = useCallback(async () => {
    await Promise.all([refetchActualUser(), refetchAssumedUser()]);
  }, [refetchActualUser, refetchAssumedUser]);

  useEffect(() => {
    setRefetchCurrentUser(onRefetch);
  }, [setRefetchCurrentUser, onRefetch]);

  return {
    fetching: fetchingActualUser || fetchingAssumedUser,
    refetch: onRefetch,
  };
};

// this hook is intended to be used in components and hooks where we know for sure the user is logged in
export const useCurrentUser = () => {
  const currentUser = useUserStore(state => state.currentUser);

  if (!currentUser) {
    throw new Error('no current user');
  }

  return currentUser;
};

// this hook is intended to be used when the user may or may not be logged in
export const useOptionalCurrentUser = () =>
  useUserStore(state => state.currentUser);

export const useActualCurrentUser = () => {
  const actualCurrentUser = useUserStore(state => state.actualCurrentUser);

  if (!actualCurrentUser) {
    throw new Error('no actual current user');
  }

  return actualCurrentUser;
};

// this hook is intended to be used when the user may or may not be logged in
export const useOptionalActualCurrentUser = () =>
  useUserStore(state => state.actualCurrentUser);

export const useLoadCurrentUserSites = ({ enable }: { enable?: boolean }) => {
  const isLoggedIn = useIsLoggedIn();

  const [currentUser, setSites] = useUserStore(state => [
    state.currentUser,
    state.setSites,
  ]);

  useEffect(() => {
    // don't cache sites when not logged in
    if (!isLoggedIn) {
      setSites([]);
    }
  }, [isLoggedIn, setSites]);

  const [{ data, fetching }] = useGetSitesQuery({
    requestPolicy: 'cache-and-network',
    pause: !isLoggedIn || !currentUser || !enable,
  });

  // update the sites any time the data changes
  useEffect(() => {
    setSites(data?.sites ?? []);
  }, [data, setSites]);

  return { fetching };
};

export const useSites = () => useUserStore(state => state.sites);
export const useVisitorSites = () => useUserStore(state => state.visitorSites);
export const useTransportSites = () =>
  useUserStore(state => state.transportSites);

export const useRefetchCurrentUser = () =>
  useUserStore(state => state.refetchCurrentUser);
