import { Input as AntInput } from 'antd';
import { InputProps as AntInputProps, InputRef } from 'antd/es/input';
import { omit } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { VisibilityToggle } from './visibility-toggle';

export interface InputProps extends AntInputProps {
  rows?: number;
  inputRef?: (node: HTMLInputElement) => void;
}

/**
 * @deprecated Use Input from xo/forms instead, this is being maintained to support flatpickr which requires the HTML ref
 */
export const Input: React.FC<InputProps> = props => {
  const { inputRef } = props;

  const componentRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef && componentRef.current?.input) {
      inputRef(componentRef.current?.input);
    }
  }, [componentRef.current?.input, inputRef]);

  const sharedProps = {
    ...omit(props, 'rows', 'inputRef'),
    className: 'xo-ant-input',
    size: props.size || 'large',
  };

  return props.type === 'password' ? (
    <AntInput.Password
      {...sharedProps}
      iconRender={visible => <VisibilityToggle visible={visible} />}
      ref={componentRef}
    />
  ) : (
    <AntInput {...sharedProps} ref={componentRef} />
  );
};
