import React, { useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { Box, Text, VStack } from 'xo/core';
import { useAppLoadingStore } from 'xo/store/app-loading-store';
import { colors } from 'xo/styles/restyle/colors';
import { shallow } from 'zustand/shallow';

// This provider doesn't wrap children because the children are the ones controlling the load state, therefore, they need to be rendered
export const AppLoaderProvider = () => {
  const [loading, title, subtitle] = useAppLoadingStore(
    state => [state.loading, state.title, state.subtitle],
    shallow,
  );

  if (!loading) return null;

  return <AppLoaderContent title={title} subtitle={subtitle} />;
};

export interface AppLoaderProps {
  loading: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const AppLoader = ({
  loading,
  title,
  subtitle,
  children,
}: AppLoaderProps) => {
  const setLoading = useAppLoadingStore(state => state.setLoading);

  useEffect(() => {
    setLoading({ loading, title, subtitle });
  }, [loading, title, subtitle, setLoading]);

  return <>{children}</>;
};

export interface AppLoaderContentProps {
  title: string;
  subtitle?: string;
}

export const AppLoaderContent = ({
  title,
  subtitle,
}: AppLoaderContentProps) => (
  <Box
    position="absolute"
    top={0}
    bottom={0}
    left={0}
    right={0}
    align="center"
    justify="center"
    bg="brandGrey.200"
    height="100%"
  >
    <VStack space="2" mt="8" align="center" justify="center">
      <Box h="8">
        <ActivityIndicator color={colors['brandOrange.500']} size="large" />
      </Box>

      <Text color="brandGrey.600" textAlign="center" fontSize="xl">
        {title}
      </Text>

      {subtitle && (
        <Text color="brandGrey.400" textAlign="center" fontSize="lg">
          {subtitle}
        </Text>
      )}
    </VStack>
  </Box>
);
