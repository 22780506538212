import classNames from 'classnames';
import { isString } from 'lodash';
import React, { useCallback } from 'react';
import styles from './button-toggle.module.css';

export interface ButtonToggleProps {
  checked?: boolean;
  onClick?: (value: any, checked: boolean) => void;
  value: any;
  className?: string;
  rounded?: boolean;
  disabled?: boolean;
  bgActiveClassName?: string;
  size?: 'lg' | 'sm';
  invert?: boolean;
  subLabel?: string | JSX.Element;
  children?: React.ReactNode;
}

export const ButtonToggle: React.FC<ButtonToggleProps> = ({
  checked,
  onClick,
  value,
  children,
  className,
  rounded,
  disabled,
  bgActiveClassName,
  size = 'lg',
  invert,
  subLabel,
}) => {
  const onClickCb = useCallback(
    () => onClick && onClick(value, !checked),
    [onClick, checked, value],
  );

  const checkedBgClass = bgActiveClassName ?? 'bg-blue-400';

  return (
    <button
      type="button"
      className={classNames(
        'px-1 text-base leading-4 transition-all duration-200 ease-out',
        className,
        {
          [styles.checkedIcon]: checked,
          [`${checkedBgClass} font-semibold text-white`]: checked,
          'font-normal text-black': !checked,
          'bg-grey-200': !checked && !invert,
          'border-2 border-solid border-grey-200 bg-white': !checked && invert,
          [styles.roundedEnds]: !rounded,
          rounded,
          'cursor-not-allowed opacity-75': disabled,
          'min-h-12': size === 'lg',
          'min-h-10': size === 'sm',
        },
      )}
      style={{ width: '100%' }}
      onClick={onClickCb}
      disabled={disabled}
      aria-label={isString(children) ? children : undefined}
      aria-pressed={checked}
    >
      {children}
      {subLabel && <div className="mt-0.5 text-xs">{subLabel}</div>}
    </button>
  );
};
