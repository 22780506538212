import { ApiMeInput } from 'xo/graphql/api/inputs/me-input.generated';
import { Exact, InputMaybe } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiCurrentUserFragment,
  CurrentUserFragmentDoc,
} from './current-user-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetCurrentUserQueryVariables = Exact<{
  input?: InputMaybe<ApiMeInput>;
}>;

export type ApiGetCurrentUserQuery = {
  __typename?: 'Query';
  me: { __typename?: 'CurrentUser' } & ApiCurrentUserFragment;
};

export const GetCurrentUserDocument = gql`
  query GetCurrentUser($input: MeInput) {
    __typename
    me(input: $input) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

export function useGetCurrentUserQuery(
  options?: Omit<Urql.UseQueryArgs<ApiGetCurrentUserQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetCurrentUserQuery, ApiGetCurrentUserQueryVariables>(
    { query: GetCurrentUserDocument, ...options },
  );
}
