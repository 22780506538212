import { useState } from 'react';
import { Button } from 'xo/components/button';
import Collapse from 'xo/components/collapse';
import { Modal } from 'xo/components/modal';
import { Box, Text } from 'xo/core';
import { ControlledCheckbox } from 'xo/forms/controlled-checkbox';
import { ControlledInput } from 'xo/forms/controlled-input';
import { Form, useForm } from 'xo/forms/form';
import { useSendTestNotificationMutation } from 'xo/graphql/api/send-test-notification-mutation.generated';
import { spacing } from 'xo/styles/restyle/spacing';
import { optionalBoolean, requiredString } from 'xo/utils/zod-utils';
import { z } from 'zod';

const labels = {
  linkPath: 'Link path',
  message: 'Message',
  followNotificationPreferences: 'Follow notification preferences',
};

const schema = z.object({
  linkPath: requiredString(labels.linkPath),
  message: requiredString(labels.message),
  followNotificationPreferences: optionalBoolean(),
});

type FormModel = z.infer<typeof schema>;

export const AdminTestNotification = () => {
  const [visible, setVisible] = useState(false);

  const methods = useForm<FormModel>({
    schema,
    defaultValues: { followNotificationPreferences: true },
  });

  const [{ fetching, data }, sendTestNotification] =
    useSendTestNotificationMutation();
  const onSendTestNotification = async () => {
    await methods.handleSubmit(values => {
      sendTestNotification({
        input: {
          ...values,
          followNotificationPreferences: !!values.followNotificationPreferences,
        },
      });
    })();
  };

  return (
    <Box my="4">
      <Box>
        <Button onPress={() => setVisible(true)}>Test notifications</Button>
      </Box>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        header="Send test notification"
      >
        <Box maxWidth={spacing['96']}>
          <Form {...methods}>
            <ControlledInput<FormModel>
              label="Link"
              name="linkPath"
              isRequired
              placeholder="visits/new"
              formControl={{
                helperBelow: `The path of the URL to which to link in the notification.`,
                w: 'full',
              }}
            />
            <ControlledInput<FormModel>
              label="Message"
              name="message"
              isRequired
              placeholder="Hello, world!"
              formControl={{
                helperBelow: 'The message to include in the notification',
                w: 'full',
              }}
            />
            <ControlledCheckbox<FormModel>
              name="followNotificationPreferences"
              label="Follow notification preferences"
              formControl={{
                helperBelow:
                  "If checked, the user's notification preferences will be respected when sending the test notification, otherwise the notification will be sent to all registered channels",
              }}
            />
            <Box w="full">
              <Button
                onPress={onSendTestNotification}
                loading={fetching}
                variant="brand"
              >
                Send
              </Button>
            </Box>
            <Collapse active={!!data}>
              <Box mt="4">
                {data && (
                  <Text fontSize="md" fontWeight="500">
                    Notification sent via{' '}
                    {data.sendTestNotification.sentVia.join(', ')}
                  </Text>
                )}
              </Box>
            </Collapse>
          </Form>
        </Box>
      </Modal>
    </Box>
  );
};
