import { isBoolean } from 'lodash';
import React from 'react';
import { Box, Text, VStack, VStackProps } from 'xo/core';
import { Color, FontSize } from 'xo/styles/restyle/theme';
import { IconCircle, IconCircleProps, IconCircleType } from './icon-circle';

const styles: Record<IconCircleType, { color: Color }> = {
  'info-bold': { color: 'black' },
  info: { color: 'blue.600' },
  error: { color: 'red.800' },
};

export interface IconHeroProps extends VStackProps {
  icon: JSX.Element;
  title: string;
  description?: string;
  type?: IconCircleType;
  circle?: Omit<IconCircleProps, 'type' | 'icon'> | false;
  headingFontSize?: FontSize;
}

export const IconHero: React.FC<IconHeroProps> = ({
  icon,
  title,
  description,
  type = 'info',
  circle,
  headingFontSize = 'xl',
  ...rest
}) => {
  const { color } = styles[type];

  return (
    <VStack
      width="100%"
      alignItems="center"
      justifyContent="center"
      space="0"
      {...rest}
    >
      {isBoolean(circle) && !circle ? (
        icon
      ) : (
        <IconCircle type={type} icon={icon} {...circle} />
      )}
      <Box mt="4">
        <Text
          color={color}
          fontWeight="600"
          textAlign="center"
          fontSize={headingFontSize}
        >
          {title}
        </Text>
      </Box>
      {description && (
        <Text color="black" fontWeight="400" textAlign="center" fontSize="lg">
          {description}
        </Text>
      )}
    </VStack>
  );
};
