import { PropsWithChildren } from 'react';
import { AppLoader } from 'xo/components/app-loader';
import { useLoadAuthStatus } from './auth-status';
import {
  useLoadCurrentUser,
  useLoadCurrentUserSites,
} from './current-user-hooks';
import { AuthStatus, UserStoreProvider } from './user-store';

const UserProviderContent = ({ enableSites, children }: UserProviderProps) => {
  const authStatus = useLoadAuthStatus();
  const isLoggedIn = authStatus === AuthStatus.Authenticated;
  const { fetching: fetchingUser } = useLoadCurrentUser();
  const { fetching: fetchingSites } = useLoadCurrentUserSites({
    enable: enableSites,
  });
  const loading =
    authStatus === AuthStatus.Loading || fetchingUser || fetchingSites;

  const renderChildren =
    authStatus === AuthStatus.Unauthenticated || (isLoggedIn && !loading);

  return (
    <AppLoader loading={!renderChildren}>
      {renderChildren && children}
    </AppLoader>
  );
};

export interface UserProviderProps extends PropsWithChildren {
  enableSites?: boolean;
}

export const UserProvider = ({
  enableSites = true,
  children,
}: UserProviderProps) => (
  <UserStoreProvider>
    <UserProviderContent enableSites={enableSites}>
      {children}
    </UserProviderContent>
  </UserStoreProvider>
);
