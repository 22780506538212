import { Tooltip } from 'antd';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useWindowSize } from 'react-hanger';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';
import { ApiSiteSummaryFragment } from 'xo/graphql/api/site-summary-fragment.generated';
import { useScreenSize, useScrollFlags } from '../../hooks/shared-hooks';
import { flexColCenterCenter } from '../app-constants';
import { SiteKindTag } from '../components/site-kind-tag';
import { Table, TableColumnProps } from '../components/table';
import { FormUpdater } from '../forms/form';
import { SettingsQuarantineCell } from './settings-quarantine-cell';
import './settings-quarantine-table.overrides.css';
import {
  SettingsQuarantineForm,
  SettingsQuarantineKindToKindSummaryModel,
  getRuleId,
} from './settings-quarantine-utils';

export interface SettingsQuarantineTableProps {
  sourceSites: ApiSiteSummaryFragment[];
  targetSites: ApiSiteSummaryFragment[];
  innerRef: React.RefObject<HTMLDivElement>;
  onCalcTableHeight?: () => void;
  editing: boolean;
  onCellChange?: () => void;
  summary: SettingsQuarantineKindToKindSummaryModel;
}

const SiteHeader: React.FC<{
  name: string;
  kind: ApiSiteKind;
  simpleTooltips: boolean;
}> = ({ name, kind, simpleTooltips }) => (
  <div
    className={classNames(flexColCenterCenter, 'whitespace-normal text-center')}
  >
    <div>
      {simpleTooltips ? (
        <span title={name}>{name}</span>
      ) : (
        <Tooltip overlay={name} trigger={['click', 'hover']}>
          <span className="cursor-pointer">{name}</span>
        </Tooltip>
      )}
    </div>

    <SiteKindTag kind={kind} className="mt-1" size="xs" />
  </div>
);

const SM_COL_LIMIT = 1;
const MD_COL_LIMIT = 3;

export const SettingsQuarantineTable: React.FC<
  SettingsQuarantineTableProps
> = ({
  sourceSites,
  targetSites,
  innerRef,
  editing,
  onCellChange,
  summary,
}) => {
  const simpleTooltips = sourceSites.length * targetSites.length > 50;

  const renderCell = useCallback(
    (source: ApiSiteSummaryFragment, target: ApiSiteSummaryFragment) => {
      const ruleId = getRuleId({ targetId: target.id, sourceId: source.id });

      return (
        <FormUpdater<SettingsQuarantineForm>
          shouldUpdate={(p, n) =>
            !isEqual(p.ruleMap[ruleId], n.ruleMap[ruleId])
          }
        >
          {form => (
            <SettingsQuarantineCell
              form={form}
              source={source}
              target={target}
              editing={editing}
              simpleTooltips={simpleTooltips}
              summary={summary}
              onChange={onCellChange}
            />
          )}
        </FormUpdater>
      );
    },
    [editing, simpleTooltips, onCellChange, summary],
  );

  const columns: TableColumnProps<ApiSiteSummaryFragment>[] = useMemo(
    () => [
      {
        key: 'source',
        dataIndex: 'name',
        title: (
          <div className="relative">
            <div className="absolute left-0 top-0  ml-4">From</div>
            <div className="absolute bottom-0 right-0 mr-4">To</div>
          </div>
        ),
        render: (name: string, site: ApiSiteSummaryFragment) => (
          <SiteHeader
            name={name}
            kind={site.kind}
            simpleTooltips={simpleTooltips}
          />
        ),
      },
      ...targetSites.map(
        target =>
          ({
            key: `target:${target.id}`,
            dataIndex: 'id',
            title: (
              <SiteHeader
                name={target.name}
                kind={target.kind}
                simpleTooltips={simpleTooltips}
              />
            ),
            render: (_sourceId: string, source: ApiSiteSummaryFragment) =>
              renderCell(source, target),
          }) as TableColumnProps<ApiSiteSummaryFragment>,
      ),
    ],
    [targetSites, renderCell, simpleTooltips],
  );

  const scrollParentSelector = '.ant-table-body';
  const {
    ref: wrapperRef,
    vertical: headerShadowActive,
    horizontal: bodyShadowActive,
  } = useScrollFlags<HTMLDivElement>(scrollParentSelector);

  const screenSize = useScreenSize();

  // Only apply the smaller size classes if we've got more columns than we'd like for that screen size
  let sizeClass = 'lg';
  if (screenSize === 'sm' && columns.length > SM_COL_LIMIT) {
    sizeClass = 'sm';
  } else if (screenSize === 'md' && columns.length > MD_COL_LIMIT) {
    sizeClass = 'md';
  }

  const { height: windowHeight } = useWindowSize();

  return (
    <div ref={wrapperRef}>
      <div className="relative" ref={innerRef}>
        <Table
          className={classNames(
            'xo-quarantine-table',
            `xo-quarantine-table-${sizeClass}`,
            {
              'xo-table-body-shadow': bodyShadowActive,
              'xo-table-header-shadow': headerShadowActive,
            },
          )}
          striped={true}
          bordered={true}
          showHeader={true}
          columns={columns}
          dataSource={sourceSites}
          pagination={false}
          tableLayout="fixed"
          scroll={{
            // Stickiness
            y: windowHeight - 400,
            x: true,
          }}
          rowKey={s => s.id}
        />
      </div>
    </div>
  );
};
