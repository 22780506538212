import { Button as AntdButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/es/button';
import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import React, { AnchorHTMLAttributes } from 'react';
import { HashLink } from 'react-router-hash-link';
import { LoadingSpinner } from 'xo/components/loading-spinner';
import { colors, padding } from 'xo/styles/tailwind-theme';
import './button.overrides.css';

export interface ButtonProps
  extends Omit<AntButtonProps, 'type' | 'shape' | 'size' | 'loading'> {
  id?: string;
  className?: string;
  type?: 'default' | 'primary' | 'dark' | 'warning' | 'grey' | 'lightblue';
  shape?: 'circle' | 'round' | 'squircle' | 'rounded';
  caps?: boolean;
  icon?: JSX.Element;
  size?: 'large' | 'small' | 'xlarge' | 'middle';
  iconRight?: boolean;
  shadow?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  alert?: boolean;
  loading?: boolean;
  to?: LocationDescriptor;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  className,
  style,
  type,
  shape,
  caps,
  size,
  icon,
  iconRight,
  shadow,
  children,
  onClick,
  loading,
  block,
  htmlType,
  alert,
  to,
  disabled,
  href,
  ...rest
}) => {
  const antType: 'primary' | 'default' =
    type && ['default', 'primary'].includes(type) ? (type as any) : 'default';
  const antShape: 'circle' | 'round' =
    shape && ['circle', 'round'].includes(shape) ? (shape as any) : undefined;

  const sharedProps = {
    children: (
      <>
        {icon && !iconRight && !loading ? (
          <span className="anticon mr-1 sm:mr-2">{icon}</span>
        ) : null}
        {!loading && children}
        {icon && iconRight && !loading ? (
          <span className="anticon ml-1 sm:ml-2">{icon}</span>
        ) : null}
        {loading && <LoadingSpinner />}
      </>
    ),
    className: classNames(className, 'flex items-center', {
      'font-medium uppercase': caps,
      'font-semibold': !caps,
      'xo-ant-btn-dark': type === 'dark',
      'xo-ant-btn-squircle': shape === 'squircle',
      'xo-ant-btn-rounded': shape === 'rounded',
      'xo-ant-btn-alert': alert,
      'xo-ant-btn-warning': type === 'warning',
      'xo-ant-btn-grey': type === 'grey',
      'xo-ant-btn-lightblue': type === 'lightblue',
      'shadow-md': shadow,
      'w-full py-5 uppercase': size === 'xlarge',
      'w-full py-3 uppercase': size === 'middle',
      'justify-start': icon && !iconRight && !loading,
      'justify-end': icon && iconRight && !loading,
      'justify-center': !icon || loading,
    }),
    id,
    style: {
      ...style,
      display: 'flex',
      color: alert ? colors.red[700] : undefined,
    },
    ...rest,
  };

  const sharedLinkProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
    ...sharedProps,
    className: classNames(sharedProps.className, 'ant-btn', {
      'ant-btn-default': !type || type === 'default',
      'ant-btn-primary': type === 'primary',
      'ant-btn-lg': size === 'large' || size === 'xlarge',
    }),
    style: {
      paddingTop: size === 'xlarge' ? padding[5] : padding[2],
      paddingBottom: size === 'xlarge' ? padding[5] : padding[2],
      ...sharedProps.style,
    } as React.CSSProperties,
    onClick,
  };

  if (href && !disabled) {
    return (
      <a {...sharedLinkProps} href={href}>
        {sharedProps.children}
      </a>
    );
  }

  if (to && !disabled) {
    return (
      // @ts-ignore HasLink doesn't define children prop
      <HashLink
        {...sharedProps}
        className={classNames(sharedProps.className, 'ant-btn', {
          'ant-btn-default': !type || type === 'default',
          'ant-btn-primary': type === 'primary',
          'ant-btn-lg': size === 'large' || size === 'xlarge',
        })}
        to={to}
        smooth={true}
        style={
          {
            paddingTop: size === 'xlarge' ? padding[5] : padding[2],
            paddingBottom: size === 'xlarge' ? padding[5] : padding[2],
            ...sharedProps.style,
          } as React.CSSProperties
        }
        onClick={onClick}
      >
        {sharedProps.children}
      </HashLink>
    );
  }

  return (
    <AntdButton
      {...sharedProps}
      type={antType}
      shape={antShape}
      size={size === 'xlarge' ? 'large' : size}
      onClick={onClick}
      block={block}
      htmlType={htmlType ?? 'button'}
      disabled={disabled || loading}
    />
  );
};

export const ButtonLine: React.FC<{ children?: React.ReactNode }> = props => (
  <AntdButton.Group {...props} />
);
