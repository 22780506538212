import classNames from 'classnames';
import React, { AriaRole } from 'react';
import {
  flexCenterBetween,
  flexCenterCenter,
  flexStartStart,
} from '../app-constants';

export const selectListClassName = classNames(
  'h-full w-full p-2 text-base transition-colors duration-200 ease-in hover:bg-grey-100',
  flexCenterBetween,
);

export interface ListProps {
  className?: string;
  itemClassName?: string;
  role?: AriaRole;
  children?: React.ReactNode;
  ordered?: boolean;
  border?: boolean;
  borderLast?: boolean;
}

export const List: React.FC<ListProps> = ({
  className,
  itemClassName,
  children,
  role,
  ordered,
  border = true,
  borderLast = true,
}) => {
  const lastIndex = React.Children.count(children) - 1;
  return React.cloneElement(ordered ? <ol /> : <ul />, {
    className: classNames(className, 'list-none pl-0'),
    role,
    children: React.Children.map(children, (child, i) => (
      <li
        className={classNames(
          {
            'border-t border-solid border-grey-300': border && i !== 0,
            'border-b border-solid border-grey-300':
              border && i === lastIndex && borderLast,
            [flexStartStart]: ordered,
          },
          'w-full list-none',
          itemClassName,
        )}
      >
        {ordered && (
          <div
            className={classNames(
              flexCenterCenter,
              'mr-2 h-6 min-h-6 w-6 min-w-6 rounded-full bg-blue-400 text-xl font-semibold text-white',
            )}
          >
            {i + 1}
          </div>
        )}
        {child}
      </li>
    )),
  });
};
