// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiRequiredActionFragment,
  RequiredActionFragmentDoc,
} from './required-action-fragment.generated';
export type ApiSiteRequiredActionsFragment = {
  __typename?: 'Site';
  id: string;
  additionalRequiredActions?: string;
  requiredActions: Array<
    { __typename?: 'RequiredAction' } & ApiRequiredActionFragment
  >;
  availableRequiredActions: Array<
    { __typename?: 'RequiredAction' } & ApiRequiredActionFragment
  >;
};

export const SiteRequiredActionsFragmentDoc = gql`
  fragment SiteRequiredActions on Site {
    __typename
    id
    requiredActions {
      ...RequiredAction
    }
    availableRequiredActions {
      ...RequiredAction
    }
    additionalRequiredActions
  }
  ${RequiredActionFragmentDoc}
`;
