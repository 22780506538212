import { FormInstance, useForm } from 'antd/es/form/Form';
import { capitalize, isEqual } from 'lodash';
import React from 'react';
import { LoadingSpinner } from 'xo/components/loading-spinner';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiSiteSummaryAdminFragment } from 'xo/graphql/api/site-summary-admin-fragment.generated';
import { Select } from '../components/select';
import { Form, FormItem, FormUpdater } from '../forms/form';
import { useUpdateSiteAdmin } from './admin-hooks';

export interface AdminSiteModuleSelectProps {
  site: Pick<ApiSiteSummaryAdminFragment, 'id' | 'modules' | 'organisation'>;
}

export const AdminSiteModuleSelect: React.FC<AdminSiteModuleSelectProps> = ({
  site,
}) => {
  const [form] = useForm();

  const { saving, onUpdateSiteAdmin } = useUpdateSiteAdmin();

  const onChange = (form: FormInstance) => {
    const newModules = form.getFieldValue('modules');
    if (!isEqual(newModules, site.modules)) {
      onUpdateSiteAdmin({
        siteId: site.id,
        modules: newModules,
        form,
      });
    }
  };

  const orgModules = new Set(site.organisation.modules);
  return (
    <Form
      form={form}
      initialValues={{
        modules: site.modules.filter(m => orgModules.has(m as ApiModule)),
      }}
    >
      <FormUpdater>
        {form => (
          <FormItem noStyle name="modules">
            <Select
              // Org module perms take precedence
              options={site.organisation.modules.sort().map(module => ({
                label: capitalize(module),
                value: module,
              }))}
              placeholder="None! You sure?"
              multi={true}
              suffixIcon={saving ? <LoadingSpinner /> : undefined}
              size="middle"
              onBlur={() => onChange(form)}
              onDeselect={() => onChange(form)}
              disabled={saving}
            />
          </FormItem>
        )}
      </FormUpdater>
    </Form>
  );
};
