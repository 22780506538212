// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiSettingsSiteUserSummaryFragment = {
  __typename?: 'User';
  id: string;
  preferredPhone?: string;
  phone?: string;
  name: string;
};

export const SettingsSiteUserSummaryFragmentDoc = gql`
  fragment SettingsSiteUserSummary on User {
    __typename
    id
    preferredPhone
    phone
    name
  }
`;
