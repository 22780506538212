import { Dayjs } from 'dayjs';
import { ApiInternalVisitorStatus } from 'xo/graphql/api/enums/internal-visitor-status.generated';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';
import {
  FormConfigModel as ApiFormConfigModel,
  FormConfigPageModel as ApiFormConfigPageModel,
  FormConfigQuestionModel as ApiFormConfigQuestionModel,
  FormConfigQuestionMostRecentVisitExternalModel as ApiFormConfigQuestionMostRecentVisitExternalModel,
  FormConfigQuestionMostRecentVisitModel as ApiFormConfigQuestionMostRecentVisitModel,
  FormSummaryModel as ApiFormSummaryModel,
  VisitSeriesReadModel as ApiVisitSeriesReadModel,
  VisitSeriesSummaryModel as ApiVisitSeriesSummaryModel,
  VisitSummaryModel as ApiVisitSummaryModel,
  DeliveryPersonReadModel,
  DeliveryReadModel,
  DenyFormSubmitReason,
  DetailedUserReadModel,
  FormConfigQuestionExpectsModel,
  FormKind,
  OrganisationReadModel,
  RiskAssessment,
  SiteSummaryModel,
  UserRoleModel,
  UserSummaryModel,
  VaccineStatusMap,
  VisitHistoryModel,
  VisitReadModel,
  VisitSeriesRecurrenceKind,
  VisitState,
} from '../api-models';

export interface VisitSummaryModel
  extends Omit<
    ApiVisitSummaryModel,
    | 'createdAt'
    | 'startDatetime'
    | 'endDatetime'
    | 'actualStartDatetime'
    | 'actualEndDatetime'
    | 'history'
  > {
  createdAt?: Dayjs;
  entryDateTime: Dayjs;
  exitDateTime: Dayjs;
  history: VisitHistoryItemModel[];
}

export interface CovidVaccinationCertificateSightingModel {
  sightedDate?: Dayjs;
  sightedBy?: UserModel;
}

export interface VisitModel
  extends Omit<
    VisitReadModel,
    | 'createdAt'
    | 'startDatetime'
    | 'endDatetime'
    | 'history'
    | 'state'
    | 'visitor'
    | 'host'
    | 'actualStartDatetime'
    | 'actualEndDatetime'
    | 'forms'
    | 'visitSeries'
    | 'borrowedAssessmentVisit'
    | 'visitorMessage'
  > {
  createdAt?: Dayjs;
  entryDateTime: Dayjs;
  exitDateTime: Dayjs;
  status: VisitState;
  history: VisitHistoryItemModel[];
  visitor: UserVisitorModel;
  host: UserModel;
  forms: FormSummaryModel[];
  // visit forcing needs to know these actual values
  actualStartDatetime?: Dayjs;
  actualEndDatetime?: Dayjs;
  scheduledStartDatetime: Dayjs;
  scheduledEndDatetime: Dayjs;
  visitSeries?: VisitSeriesSummaryModel;
  borrowedAssessmentVisit?: VisitSummaryModel;
  visitorMessage?: string;
  otherPurpose?: string;
  repeatFrequency?: VisitSeriesRecurrenceKind;
  repeatEndDate?: Dayjs;
}

export interface VisitSeriesSummaryModel
  extends Omit<ApiVisitSeriesSummaryModel, 'startDate' | 'endDate'> {
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface VisitSeriesReadModel
  extends Omit<
    ApiVisitSeriesReadModel,
    'startDate' | 'endDate' | 'host' | 'visitor' | 'visits'
  > {
  startDate: Dayjs;
  endDate: Dayjs;
  host: UserModel;
  visitor: UserVisitorModel;
  visits: VisitModel[];
}

export interface VisitSeriesSummaryModel
  extends Omit<ApiVisitSeriesSummaryModel, 'startDate' | 'endDate'> {
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface VisitSeriesReadModel
  extends Omit<
    ApiVisitSeriesReadModel,
    'startDate' | 'endDate' | 'host' | 'visitor' | 'visits'
  > {
  startDate: Dayjs;
  endDate: Dayjs;
  host: UserModel;
  visitor: UserVisitorModel;
  visits: VisitModel[];
}

export interface DeliveryPersonModel
  extends Omit<DeliveryPersonReadModel, 'covidVaccinationCertificateSighting'> {
  covidVaccinationCertificateSighting?: CovidVaccinationCertificateSightingModel;
}

export interface DeliveryModel
  extends Omit<
    DeliveryReadModel,
    'createdAt' | 'startDatetime' | 'deliveryPerson' | 'form'
  > {
  createdAt?: Dayjs;
  entryDateTime: Dayjs;
  deliveryPerson: DeliveryPersonModel;
  form?: FormResponse;
}

export interface ScheduleEventModel {
  entryDateTime: Dayjs;
  site: SiteSummaryModel;
  visit: VisitModel | null;
  delivery: DeliveryModel | null;
}

export interface ScheduleModel {
  visits: VisitModel[];
  deliveries: DeliveryModel[];
}

export type NotificationType = 'sms' | 'email' | 'devicePush';

export interface UserModel
  extends Omit<
    DetailedUserReadModel,
    | 'notifyWithSms'
    | 'notifyWithEmail'
    | 'notifyWithDevicePush'
    | 'quietTimeStart'
    | 'quietTimeEnd'
    | 'createdAt'
    | 'lastSeen'
    | 'kind'
    | 'internalVisitorStatusAtOrganisation'
    | 'organisation'
    | 'kioskUserAtSite'
  > {
  notifications: NotificationType[];
  quietTimeStart?: Dayjs;
  quietTimeEnd?: Dayjs;
  createdAt?: Dayjs;
  lastSeen?: Dayjs;
  kind: ApiUserKind;
  internalVisitorStatusAtOrganisation?: ApiInternalVisitorStatus;
  // what's the status at the current visit's org, when "current visit's org" makes sense
  internalVisitorStatusAtVisitOrg?: ApiInternalVisitorStatus;
  organisation?: Omit<OrganisationReadModel, 'modules'> & {
    modules: ApiModule[];
  };
  roles: UserRoleModel[];
  kioskUserAtSite?: Pick<SiteSummaryModel, 'id' | 'shortCode'>;
}

export interface UserVisitorModel
  extends Pick<
    UserModel,
    | 'id'
    | 'name'
    | 'email'
    | 'phone'
    | 'company'
    | 'agreedTerms'
    | 'notifications'
    | 'vaccineStatuses'
    | 'internalVisitorStatusAtOrganisation'
    | 'internalVisitorStatusOrganisationId'
    | 'internalVisitorStatusAtVisitOrg'
  > {
  covidVaccinationCertificateSighting?: CovidVaccinationCertificateSightingModel;
}

export interface VisitHistoryItemModel
  extends Omit<
    VisitHistoryModel,
    'transitionDatetime' | 'toState' | 'fromState' | 'qrTimestamp'
  > {
  status: VisitState;
  date: Dayjs;
  qrTimestamp?: Dayjs;
}

export type QuestionnaireBlockedReason = DenyFormSubmitReason | 'CANCELLED';

export interface FormConfigPageModel
  extends Omit<ApiFormConfigPageModel, 'questions'> {
  path: string;
  reviewPage?: boolean;
  questions: FormConfigQuestionModel[];
}

export interface FormConfigQuestionModel
  extends Omit<ApiFormConfigQuestionModel, 'mostRecentVisits' | 'expects'> {
  mostRecentVisits: FormConfigQuestionMostRecentVisitModel[];
  expects: FormConfigQuestionExpectsModel;
  order: number;
  dependentQuestions: FormConfigQuestionModel[];
}

export interface FormConfigQuestionMostRecentVisitExternalModel
  extends Omit<
    ApiFormConfigQuestionMostRecentVisitExternalModel,
    'startDatetime' | 'endDatetime' | 'forms' | 'history'
  > {
  entryDateTime: Dayjs;
  exitDateTime: Dayjs;
  history: VisitHistoryItemModel[];
  forms: FormSummaryModel[];
}
export interface FormConfigQuestionMostRecentVisitModel
  extends Omit<
    ApiFormConfigQuestionMostRecentVisitModel,
    'internalVisit' | 'internalDelivery' | 'externalVisit'
  > {
  internalVisit?: VisitModel;
  internalDelivery?: DeliveryModel;
  externalVisit?: FormConfigQuestionMostRecentVisitExternalModel;
}

export interface FormConfigModel extends Omit<ApiFormConfigModel, 'pages'> {
  pages: FormConfigPageModel[];
}

// FIXME Update to match FormReadModel
export interface VisitQuestionnaireModel {
  id?: string;
  token?: string;
  visit: VisitModel;
  kind?: FormKind;
  config?: FormConfigModel;
  blockedReason?: QuestionnaireBlockedReason;
  responses: QuestionResponseMapModel;
}

export enum DateUnsureAnswer {
  MORE_THAN_QUARANTINE = 'MORE_THAN_QUARANTINE',
  NEVER = 'NEVER',
}

export type DateUnsureValue = Dayjs | DateUnsureAnswer;

export type BaseBiosecurityQuestionnaireModel = {
  id: string;
  answers?: BiosecurityQuestionnaireAnswersModel;
};

export type BiosecurityQuestionnaireAnswersModel = {
  [questionId: string]: any;
};

export interface PrevisitQuestionnaireForm
  extends BaseBiosecurityQuestionnaireModel {
  visit: VisitModel;
}

export interface OnsiteQuestionnaireForm
  extends BaseBiosecurityQuestionnaireModel {
  actionsTaken: BiosecurityActionsTakenModel;
}

export type BiosecurityActionsTakenModel = { [actionId: string]: boolean };

export enum MoveDirection {
  Previous = -1,
  Next = 1,
}

export type NotificationsModel = ('sms' | 'email' | 'devicePush')[];

export interface VisitRequest {
  visit: VisitModel;
}

export interface CancelVisitForm {
  reason?: string;
  sendReasonToVisitor: boolean;
}

export interface CancelVisitRequest extends CancelVisitForm {
  id: string;
}

export interface AnswerProvenanceModel {
  answeredAt: Dayjs;
  // FIXME Not optional in api-models.d.ts for some reason
  expiresAt?: Dayjs;
}

export interface QuestionResponseModel {
  value: any;
  risk: RiskAssessment;
  riskFromReview: boolean;
  provenance?: AnswerProvenanceModel;
}

export interface FormResponse {
  id: string;
  responses: QuestionResponseMapModel;
  config?: FormConfigModel;
  riskAssessment?: RiskAssessment;
  kind: FormKind;
  blockedReason?: QuestionnaireBlockedReason;
  completedAt?: Dayjs;
  completedOnDeviceOf: UserSummaryModel | undefined;
}

// The server response model
export type QuestionResponseMapModel = {
  [questionId: string]: QuestionResponseModel;
};

export interface HostReviewForm {
  reviewQuestions: { [questionId: string]: HostReviewFormQuestion };
  denyEntry?: {
    notifications: NotificationsModel;
    sendReasonToVisitor: boolean;
  };
  entryReason?: string;
  sendSiteEntryMessageToVisitor: boolean;
}

export interface HostReviewFormQuestion {
  // store the original risk determined by the API to see if the user agreed with it or not
  originalRisk: RiskAssessment;
  // the risk the user can edit
  reviewedRisk?: RiskAssessment;
  comment?: string;
}
export type FormMap = {
  [kind in FormKind]: FormResponse | undefined;
};

export interface FormSummaryModel
  extends Omit<ApiFormSummaryModel, 'completedAt'> {
  completedAt?: Dayjs;
}

export interface CheckInFormModel {
  id?: string;
  name: string;
  phone: string;
  company: string;
  rego?: string[];
  deliveryType: string;
  mins: number;
  vaccineStatuses: VaccineStatusMap;
  answers?: AnswerValueMap;
}

export type VisitDetailsFormModel = {
  visit: VisitModel;
  notifications: NotificationsModel;
  kioskMode: boolean;
};

// The local form answer map
export type AnswerValueMap = { [questionId: string]: any };
