import dayjs, { Dayjs } from 'dayjs';
import { omit } from 'lodash';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { coalesceEmpty } from 'xo/utils/transform-utils';
import {
  DeliveryWriteModel as ApiDeliveryWriteModel,
  DeliveryCancelRequest,
  DeliveryCheckInRequest,
  DeliveryCreateUpdateRequest,
  DeliveryPersonListResponse,
  DeliveryResponse,
} from '../api-models';
import { ErrorPageContext } from '../app/components/error-page-provider';
import {
  CovidVaccinationCertificateSightingModel,
  DeliveryModel,
  DeliveryPersonModel,
} from '../models/visitor-log-models';
import { AnalyticsEvent, captureEvent } from './analytics';
import { getApi } from './axios-instance';
import {
  covidVaccinationSightingTransformer,
  datetimeTransformer,
} from './network-transformers';
import {
  readDeliveryPersonTransformer,
  readDeliveryTransformer,
} from './transformers';

const deliveryApi = getApi('delivery');
const deliveryPeopleApi = getApi('delivery-people');

export const deliveryQueryKeys = {
  delivery: (id: string) => `delivery:${id}`,
  deliveryPeople: 'deliveryPeople',
};

type DeliveryWriteModel = Omit<ApiDeliveryWriteModel, 'startDatetime'> & {
  entryDateTime?: Dayjs;
};

export const otherDeliveryTypeValue = 'OTHER';

const writeDeliveryTransformer = (
  d: DeliveryWriteModel,
): ApiDeliveryWriteModel => ({
  startDatetime: d.entryDateTime && datetimeTransformer(d.entryDateTime),
  site: { id: d.site.id },
  deliveryPerson: omit(
    d.deliveryPerson,
    'vaccineStatuses',
    'covidVaccinationCertificateSighting',
  ),
  regos: d.regos,
  deliveryType: d.deliveryType ?? otherDeliveryTypeValue,
  deliveryTypeOther: d.deliveryTypeOther,
  vaccineStatuses: d.vaccineStatuses ?? {},
  id: d.id,
  mins: d.mins,
  questionnaireFlow: d.questionnaireFlow,
  questions: d.questions ?? {},
  hostNotes: coalesceEmpty(d.hostNotes),
});

export const useGetDelivery = (id?: string) => {
  const { onNotFoundAdminAutoAssume } = useContext(ErrorPageContext);

  return useQuery<DeliveryModel>(
    deliveryQueryKeys.delivery(id!),
    () =>
      getApi('delivery', {
        onErrorPage: onNotFoundAdminAutoAssume({ type: 'delivery', id: id! }),
      })
        .get<DeliveryResponse>(`/${id}`)
        .then(response => readDeliveryTransformer(response.data.delivery)),
    { enabled: !!id, retry: false, cacheTime: 0 },
  );
};

export const useGetDeliveryPeople = () =>
  useQuery<DeliveryPersonModel[]>(
    deliveryQueryKeys.deliveryPeople,
    () =>
      deliveryPeopleApi
        .get<DeliveryPersonListResponse>(`/`)
        .then(response =>
          response.data.deliveryPeople.map(readDeliveryPersonTransformer),
        ),
    { retry: false, cacheTime: 0 },
  );

export const usePostDelivery = () =>
  useMutation(
    (request: { delivery: DeliveryWriteModel }) => {
      const data: DeliveryCreateUpdateRequest = {
        delivery: writeDeliveryTransformer(request.delivery),
      };

      return deliveryApi.post<DeliveryResponse>(`/`, data).then(response => {
        const delivery = readDeliveryTransformer(response.data.delivery);

        captureEvent(AnalyticsEvent.DeliveryCheckIn, {
          site: delivery.site,
          organisation: delivery.site.organisation,
          deliveryId: delivery.id,
          deliveryPersonId: delivery.deliveryPerson.id,
          deliveryType: delivery.deliveryType,
          mins: delivery.mins,
        });

        return delivery;
      });
    },
    { retry: false },
  );

export const usePostCheckInDelivery = () =>
  useMutation(
    (request: { delivery: DeliveryWriteModel; checkInType: string }) => {
      const timestamp = dayjs().toISOString();
      const data: DeliveryCheckInRequest = {
        delivery: request.delivery,
        checkInType: request.checkInType,
        termsAndPrivacy: {
          termsVersion: timestamp,
          privacyVersion: timestamp,
        },
      };

      return getApi('delivery', { open: true })
        .post<DeliveryResponse>(`/check-in`, data)
        .then(response => readDeliveryTransformer(response.data.delivery));
    },
    { retry: false },
  );

export const usePutDelivery = () => {
  const client = useQueryClient();

  return useMutation(
    (request: { delivery: DeliveryWriteModel }) => {
      const data: DeliveryCreateUpdateRequest = {
        delivery: writeDeliveryTransformer(request.delivery),
      };

      return deliveryApi
        .put<DeliveryResponse>(`/${request.delivery.id}`, data)
        .then(response => {
          const delivery = readDeliveryTransformer(response.data.delivery);
          // Required when editing because page doesn't re-mount
          client.setQueryData(
            deliveryQueryKeys.delivery(delivery.id),
            delivery,
          );
          return delivery;
        });
    },
    { retry: false },
  );
};

export const useCancelDelivery = () =>
  useMutation(
    (request: { id: string; reason: string }) => {
      const data: DeliveryCancelRequest = {
        reason: request.reason ?? '',
      };
      return deliveryApi
        .post<DeliveryResponse>(`/${request.id}/cancel`, data)
        .then(response => readDeliveryTransformer(response.data.delivery));
    },
    { retry: false },
  );

export const usePostDeliveryPersonCovidVaccinationSighting = () =>
  useMutation(
    (data: CovidVaccinationCertificateSightingModel & { id: string }) => {
      return deliveryPeopleApi.post(
        `/${data.id}/covid-vaccination-sighting`,
        covidVaccinationSightingTransformer(data),
      );
    },
    { retry: false },
  );

export const useDeleteDelivery = () => {
  const client = useQueryClient();
  return useMutation(
    (request: { id: string }) =>
      deliveryApi.delete<DeliveryResponse>(`/${request.id}`).then(response => {
        const delivery = readDeliveryTransformer(response.data.delivery);
        // Required when editing because page doesn't re-mount
        client.setQueryData(deliveryQueryKeys.delivery(delivery.id), delivery);
        return delivery;
      }),
    { retry: false },
  );
};
