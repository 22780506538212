import { ResponsiveValue, createTheme } from '@shopify/restyle';
import { Platform } from 'react-native';
import { borderRadii } from './border-radii';
import { borderWidth } from './border-width';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { fontSize } from './font-size';
import { fontWeight } from './font-weight';
import { shadow } from './shadow';
import { spacing } from './spacing';

export const restyleTheme = createTheme({
  colors,
  spacing,
  breakpoints,
  rounded: borderRadii,
  fontSize,
  fontWeight: Platform.select({ native: fontWeight }),
  borderWidth,
  textVariants: {
    defaults: {
      fontFamily: Platform.select({
        web: 'Inter',
        native: 'Inter_400Regular',
      }),
      fontSize: fontSize.md,
    },
  },
});

export type RestyleTheme = typeof restyleTheme;

export type BorderWidth = keyof typeof borderWidth;
export type BorderRadius = keyof typeof borderRadii;
export type FontSize = keyof typeof fontSize;
export type Color = keyof typeof colors;
export type FontWeight = keyof typeof fontWeight;
export type Spacing = keyof typeof spacing;
export type Shadow = keyof typeof shadow;
export type FlexDirectionType =
  | 'row'
  | 'column'
  | 'column-reverse'
  | 'row-reverse';

export type DisplayType = 'flex' | 'none';

export type Responsive<T> = ResponsiveValue<T, RestyleTheme['breakpoints']>;
