import { ApiUserKind } from 'xo/graphql/api/enums/user-kind.generated';

import gql from 'graphql-tag';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
export type ApiUserSummaryFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  phone?: string;
  preferredPhone?: string;
  kind: ApiUserKind;
  organisation?: {
    __typename?: 'Organisation';
  } & ApiOrganisationSummaryFragment;
};

export const UserSummaryFragmentDoc = gql`
  fragment UserSummary on User {
    __typename
    id
    name
    phone
    preferredPhone
    kind
    organisation {
      ...OrganisationSummary
    }
  }
  ${OrganisationSummaryFragmentDoc}
`;
