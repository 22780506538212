import React, { createContext, useCallback } from 'react';
import { useGetSystemQuery } from 'xo/graphql/api/get-system-query.generated';
import { ApiWebAppConfigFragment } from 'xo/graphql/api/web-app-config-fragment.generated';
import { useIsLoggedIn } from 'xo/login/auth-status';
import { usePolling } from '../../hooks/shared-hooks';

// once every 5 minutes
const refetchSystemQueryIntervalMs = 5 * 60 * 1000;

export interface SystemConfigProps extends ApiWebAppConfigFragment {}

export const SystemConfigContext = createContext<SystemConfigProps | null>(
  null,
);

export const SystemConfigProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const [{ data }, refetch] = useGetSystemQuery({
    requestPolicy: 'cache-and-network',
    // this query requires authentication
    pause: !isLoggedIn,
  });

  const refetchIfLoggedIn = useCallback(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);
  usePolling(refetchIfLoggedIn, refetchSystemQueryIntervalMs);

  return (
    <SystemConfigContext.Provider value={data?.system.webAppConfig ?? null}>
      {children}
    </SystemConfigContext.Provider>
  );
};
