import classNames from 'classnames';
import React, { useCallback } from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgWarning } from 'xo/svg/svg-warning';
import { confirm } from '../components/modal';
import { Button } from './button';

const noRestoreMessage = 'Deleted. To undelete, contact your nearest engineer.';

export interface DeleteButtonProps {
  entity: string;
  deleted?: boolean;
  deleting?: boolean;
  onClick: () => void;
  buttonRestores?: boolean;
  otherRestoreInstructions?: string;
  disabled?: boolean;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  entity,
  deleted,
  deleting,
  onClick,
  buttonRestores,
  otherRestoreInstructions,
  disabled,
}) => {
  const action = deleted ? 'Restore' : 'Delete';

  const showInstructions = deleted && !buttonRestores;
  const supportsRestore = buttonRestores || otherRestoreInstructions;

  const onClickCb = useCallback(
    () =>
      confirm({
        content: (
          <div>
            <div>
              Are you sure you want to {action.toLowerCase()} this {entity}?
            </div>
            <div
              className={classNames('text-base', {
                'font-semibold text-red-800': !supportsRestore,
              })}
            >
              {deleted
                ? null
                : supportsRestore
                  ? `This can be undone, but you will not be able to find this page via the ${entity} list`
                  : 'This cannot be undone'}
            </div>
          </div>
        ),
        okText: action,
        onOk: onClick,
      }),
    [action, entity, onClick, supportsRestore, deleted],
  );

  return showInstructions ? (
    <span className="font-semibold text-red-800">
      {otherRestoreInstructions ?? noRestoreMessage}
    </span>
  ) : (
    <Button
      type="warning"
      icon={<SvgWarning fill={colors.red[700]} />}
      loading={deleting}
      onClick={onClickCb}
      disabled={showInstructions || disabled}
    >
      {action}
    </Button>
  );
};
