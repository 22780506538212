import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiSettingsSiteFragment,
  SettingsSiteFragmentDoc,
} from './settings-site-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetSitesQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetSitesQuery = {
  __typename?: 'Query';
  sites: Array<{ __typename?: 'Site' } & ApiSettingsSiteFragment>;
};

export const GetSitesDocument = gql`
  query GetSites {
    __typename
    sites(input: {}) {
      ...SettingsSite
    }
  }
  ${SettingsSiteFragmentDoc}
`;

export function useGetSitesQuery(
  options?: Omit<Urql.UseQueryArgs<ApiGetSitesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiGetSitesQuery, ApiGetSitesQueryVariables>({
    query: GetSitesDocument,
    ...options,
  });
}
