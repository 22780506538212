import { ApiFileAttachmentKind } from 'xo/graphql/api/enums/file-attachment-kind.generated';

import gql from 'graphql-tag';
export type ApiFileAttachmentFragment = {
  __typename?: 'FileAttachment';
  id: string;
  downloadUrl: string;
  kind: ApiFileAttachmentKind;
  size: number;
};

export const FileAttachmentFragmentDoc = gql`
  fragment FileAttachment on FileAttachment {
    __typename
    id
    downloadUrl
    kind
    size
  }
`;
