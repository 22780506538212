import { ApiUserDevicePushTokenKind } from 'xo/graphql/api/enums/user-device-push-token-kind.generated';
import { ApiUserDevicePushTokenStatus } from 'xo/graphql/api/enums/user-device-push-token-status.generated';

import gql from 'graphql-tag';
export type ApiUserDevicePushTokenFragment = {
  __typename?: 'UserDevicePushToken';
  id: string;
  deviceName: string;
  deviceId: string;
  status: ApiUserDevicePushTokenStatus;
  kind: ApiUserDevicePushTokenKind;
};

export const UserDevicePushTokenFragmentDoc = gql`
  fragment UserDevicePushToken on UserDevicePushToken {
    __typename
    id
    deviceName
    deviceId
    status
    kind
  }
`;
