import { create } from 'zustand';

export interface AppLoadingState {
  loading: boolean;
  title: string;
  subtitle?: string;
  setLoading: (props: {
    loading: boolean;
    title?: string;
    subtitle?: string;
  }) => void;
}

export const useAppLoadingStore = create<AppLoadingState>(set => ({
  title: 'Logged in',
  subtitle: 'Redirecting you now',
  loading: true,
  setLoading: ({ loading, title, subtitle }) =>
    set({ loading, title, subtitle }),
}));
