import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import React from 'react';
import { HStack } from 'xo/core';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgArrow } from 'xo/svg/svg-arrow';
import { SvgMenu } from 'xo/svg/svg-menu';
import { toId } from 'xo/utils/string-utils';
import { flexCenterCenter } from '../app-constants';
import { AppHeaderDowntimeBanner } from './app-header-downtime-banner';
import { Container } from './container';
import { NavBarDropdown, NavBarDropdownOption } from './navbar-dropdown';
import { OptionalButton } from './optional-button';
import { StageTag } from './stage-tag';

const transitionClassName = 'transition-all duration-200 ease-out';

export interface AppHeaderProps {
  title: string;
  inverted?: boolean;
  back?: boolean;
  titleOptions?: NavBarDropdownOption[];
  collapse?: boolean;
  icon?: JSX.Element;
  right?: React.ReactNode;
  sidebar?: React.ReactNode;
  profile?: React.ReactNode;
  predictedReleaseTime?: Dayjs;
  onClick?: () => void;
}

export const AppHeader = ({
  title,
  inverted,
  back,
  titleOptions,
  collapse,
  icon,
  right,
  sidebar,
  profile,
  predictedReleaseTime,
  onClick,
}: AppHeaderProps) => {
  const bannerClassName = classNames({
    'bg-blue-600 text-white': !inverted,
    'bg-grey-100 text-blue-600': inverted || back,
  });

  const renderedTitle = (
    <div className={flexCenterCenter}>
      {icon && (
        <span className="mr-2">{React.cloneElement(icon, { scale: 1.3 })}</span>
      )}
      <h1
        className={classNames(
          'truncate bg-transparent text-lg font-bold leading-6 -tracking-0.25px',
          transitionClassName,
          {
            'text-white': !inverted,
            'text-blue-600': inverted || back,
          },
        )}
        id={toId(title)}
      >
        {title}
      </h1>
    </div>
  );

  const downtimeBanner = predictedReleaseTime ? (
    <AppHeaderDowntimeBanner
      predictedReleaseTime={predictedReleaseTime}
      large={collapse}
    />
  ) : null;

  return (
    <>
      <div
        className={classNames(
          'fixed z-50 h-12 w-full shadow-lg print:hidden',
          transitionClassName,
          bannerClassName,
        )}
      >
        {sidebar && (
          <div
            className={classNames(
              'fixed hidden h-screen w-64 bg-blue-600 shadow-r-lg',
              { 'lg:block': !collapse },
            )}
          >
            {sidebar}
          </div>
        )}

        <Container large={collapse} animated={true}>
          <div className="relative flex h-full items-center justify-between">
            <div className="item-center flex truncate">
              <OptionalButton
                className={classNames('mr-2', {
                  'lg:hidden': !collapse && !back,
                })}
                onClick={onClick}
                id={onClick ? 'nav-menu' : undefined}
              >
                {onClick && back ? (
                  <SvgArrow
                    direction="l"
                    fill={inverted ? colors.blue[400] : colors.white}
                    scale={1.2}
                  />
                ) : sidebar ? (
                  <SvgMenu
                    fill={inverted ? colors.blue[400] : colors.white}
                    scale={1.2}
                  />
                ) : null}
              </OptionalButton>
              {titleOptions && titleOptions.length > 0 ? (
                <NavBarDropdown options={titleOptions} inverted={inverted}>
                  {renderedTitle}
                </NavBarDropdown>
              ) : (
                renderedTitle
              )}
            </div>

            <HStack alignItems="center" space="2">
              {right}

              <StageTag />

              {profile}
            </HStack>
          </div>
        </Container>
        {downtimeBanner && (
          <div className="fixed -z-10 w-full">{downtimeBanner}</div>
        )}
      </div>
      <div className="h-12 w-full print:hidden" />
      <div className="invisible w-full">
        {
          /* a hidden non-fixed rendering that gives the right vertical spacing for the real content*/
          downtimeBanner
        }
      </div>
    </>
  );
};
