// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiFileAttachmentFragment,
  FileAttachmentFragmentDoc,
} from './file-attachment-fragment.generated';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
export type ApiOrganisationFragment = {
  __typename?: 'Organisation';
  logo?: { __typename?: 'FileAttachment' } & ApiFileAttachmentFragment;
} & ApiOrganisationSummaryFragment;

export const OrganisationFragmentDoc = gql`
  fragment Organisation on Organisation {
    __typename
    ...OrganisationSummary
    logo {
      ...FileAttachment
    }
  }
  ${OrganisationSummaryFragmentDoc}
  ${FileAttachmentFragmentDoc}
`;
