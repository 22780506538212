import dayjs, { Dayjs } from 'dayjs';
import { LocationListener } from 'history';
import { isEqual, isUndefined } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useCurrentUser, useSites } from 'xo/login/current-user-hooks';
import { Module } from '../api-models';
import { hasModuleAccess } from './auth-utils';

const queryParamArrayFormat = 'bracket';

export const createQueryParams = (params: object) =>
  qs.stringify(params, { arrayFormat: queryParamArrayFormat });

const parse = (search: string) =>
  qs.parse(search, { arrayFormat: queryParamArrayFormat }) ?? {};

export const useSetQueryParams = () => {
  return useCallback((query: object) => {
    const currentParams = parse(window.location.search);

    const search = createQueryParams({ ...currentParams, ...query });
    // React router remounts the parent component with history.replace for some reason
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname.split('?')[0]}${search ? '?' : ''}${search}`,
    );
  }, []);
};

export const useQueryParams = () => {
  useLocation();
  // useLocation doesn't include mocked query params in stories, so register the hook, but use the search directly
  return parse(window.location.search);
};

export const useQueryParamState = <T>(
  paramName: string,
  defaultValue?: T,
): [T | undefined, (value?: T) => void] => {
  const param = useQueryParams()[paramName] as unknown as T;
  const [paramValue, setParamValue] = useState(param ?? defaultValue);
  const setQueryParams = useSetQueryParams();

  const onSetParamValue = useCallback(
    (value?: T) => {
      setParamValue(value);
      setQueryParams({
        [paramName]: isUndefined(value) ? null : value,
      });
    },
    [setParamValue, setQueryParams, paramName],
  );

  return [paramValue, onSetParamValue];
};

export const useQueryParamArrayState = <T extends string>(
  paramName: string,
  defaultValues?: T[],
): [
  values: T[],
  ops: {
    add: (value: T) => void;
    remove: (value: T) => void;
    update: (values: T[]) => void;
  },
] => {
  const [param, setParam] = useQueryParamState<T[]>(paramName, defaultValues);

  const update = useCallback((values: T[]) => setParam(values), [setParam]);

  const values = useMemo(() => param ?? [], [param]);

  const add = useCallback(
    (value: T) => {
      if (!values.some(v => isEqual(v, value))) {
        update(values.concat(value));
      }
    },
    [values, update],
  );

  const remove = useCallback(
    (value: T) => {
      update(values.filter(v => !isEqual(v, value)));
    },
    [update, values],
  );

  return [values, { add, remove, update }];
};

export const useSiteQueryParams = ({
  onlyHostSitesByDefault,
  module,
}: {
  onlyHostSitesByDefault?: boolean;
  module?: Module;
}): [string[], (values: string[]) => void] => {
  const allSites = useSites();
  const user = useCurrentUser();
  const sites = useMemo(
    () => allSites.filter(site => hasModuleAccess(site, module)),
    [module, allSites],
  );

  const [siteNames, { update: setSiteNames }] = useQueryParamArrayState<string>(
    'site',
    onlyHostSitesByDefault
      ? user.hostAtSites
          .filter(s => hasModuleAccess(s, module))
          .map(s => s.name)
      : undefined,
  );

  const setSiteIds = useCallback(
    (ids: string[]) => {
      const siteMap = new Map(sites.map(s => [s.id, s.name]));
      setSiteNames(ids.map(id => siteMap.get(id)!).filter(s => s));
    },
    [setSiteNames, sites],
  );

  const siteMap = new Map(sites?.map(s => [s.name, s]));
  const values = Array.from(
    new Set(
      siteNames
        .map(name => siteMap.get(name)!)
        .filter(s => s)
        .map(s => s.id),
    ),
  );

  return [values, setSiteIds];
};

export const useDateQueryParam = (
  paramName: string,
  defaultValue?: Dayjs,
  format?: string,
): [Dayjs | undefined, (value?: Dayjs) => void] => {
  const [param, setParam] = useQueryParamState(
    paramName,
    defaultValue?.format(format),
  );

  return [
    param ? dayjs(param) : undefined,
    useCallback(
      (value?: Dayjs) => setParam(value?.format(format)),
      [setParam, format],
    ),
  ];
};

export const useIntQueryParam = (
  paramName: string,
  defaultValue?: number,
): [number | undefined, (value?: number) => void] => {
  const [param, setParam] = useQueryParamState(
    paramName,
    defaultValue ? String(defaultValue) : undefined,
  );

  return [
    param ? parseInt(param) : undefined,
    useCallback(
      (value?: number) =>
        value ? setParam(String(value)) : setParam(undefined),
      [setParam],
    ),
  ];
};

export const useBoolQueryParam = (
  paramName: string,
  defaultValue?: boolean,
): [boolean | undefined, (value?: boolean) => void] => {
  const [param, setParam] = useQueryParamState(
    paramName,
    defaultValue ? String(defaultValue) : undefined,
  );

  return [
    param === 'true',
    useCallback(
      (value?: boolean) =>
        value ? setParam(String(value)) : setParam(undefined),
      [setParam],
    ),
  ];
};

export const useOnLocationChange = (cb: LocationListener<unknown>) => {
  const history = useHistory();
  useEffect(() => history.listen(cb), [cb, history]);
};

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export const useSearchParams = (): [
  ReadOnlyURLSearchParams,
  (search: URLSearchParams) => void,
] => {
  const { search } = useLocation();
  const history = useHistory();

  return [
    useMemo(
      () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
      [search],
    ),
    (search: URLSearchParams) => history.replace({ search: search.toString() }),
  ];
};

export const useRouteIdMatch = (route: (id?: string) => string) => {
  const match = useRouteMatch<{ id?: string }>(route());
  return match?.params.id;
};
