// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiRequiredActionFragment = {
  __typename?: 'RequiredAction';
  id: string;
  label: string;
};

export const RequiredActionFragmentDoc = gql`
  fragment RequiredAction on RequiredAction {
    __typename
    id
    label
  }
`;
