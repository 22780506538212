import {
  init,
  reactRouterV5BrowserTracingIntegration,
  withSentryRouting,
} from '@sentry/react';
import { Route } from 'react-router-dom';
import getClientId from 'xo/client-id';
import { STAGE, env } from '../app/env';
import history from '../hooks/history';

export const SentryRoute = withSentryRouting(Route as any);

if (!env.REACT_APP_STORYBOOK) {
  init({
    enabled: !!env.REACT_APP_SENTRY_DSN,
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: STAGE,
    integrations: [
      reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
    // use origin if it's a deployed env. otherwise we're localhost and we might be testing sentry
    tracePropagationTargets: window.location.origin.includes('exoflare')
      ? [window.location.origin]
      : undefined,
    sendDefaultPii: false,
    release: env.REACT_APP_COMMIT_FRONTEND,
    initialScope: {
      user: {
        clientId: getClientId(),
      },
    },
    replaysOnErrorSampleRate: 0.05,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
}
