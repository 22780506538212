import { useMemo, useState } from 'react';
import { UseQueryArgs } from 'urql';
import { useQuery } from 'xo/graphql/urql-provider';
import { useFuzzySearch } from 'xo/hooks/fuzzy-search-hooks';
import { getQueryData } from 'xo/utils/graphql-utils';
import {
  PaginatedTableView,
  SearchColumnProps,
  getTableSearchPaths,
} from './table-utils';

export const useFuzzySearchTableRows = <T extends object>({
  columns,
  data,
  searchString,
}: {
  searchString?: string;
  columns: SearchColumnProps<T>[];
  data: readonly T[];
}) => {
  const searchPaths = useMemo(() => getTableSearchPaths(columns), [columns]);

  const fuzzySearch = useFuzzySearch(data, searchPaths);
  if (searchString && searchPaths.length) {
    const results = fuzzySearch(searchString);
    return results.map(r => r.item);
  }
  return data;
};

export interface ServerPaginatedQueryPage<TData> {
  data: TData;
  totalRecords: number;
  totalPages: number;
}

export type ServerPaginatedQuery<TData = object> = {
  __typename?: 'Query';
  [resolver: symbol]: ServerPaginatedQueryPage<TData>;
};

export interface ServerPaginatedQueryVariablesInput<TSortColumnEnum, TFilter>
  extends PaginatedTableView<TSortColumnEnum> {
  filter: TFilter;
}

export interface ServerPaginatedQueryVariables<TSortColumnEnum, TFilter> {
  input: ServerPaginatedQueryVariablesInput<TSortColumnEnum, TFilter>;
}

// defines a useQuery hook that takes in the expected pagination parameters as input
type UseQueryHook<TQuery, TSortColumnEnum, TFilter> = (
  args: Omit<
    UseQueryArgs<
      ServerPaginatedQueryVariables<TSortColumnEnum, TFilter>,
      TQuery
    >,
    'query'
  >,
) => ReturnType<
  typeof useQuery<
    TQuery,
    ServerPaginatedQueryVariables<TSortColumnEnum, TFilter>
  >
>;

export type UseServerPaginatedTableOptions<
  TQuery extends ServerPaginatedQuery,
  TSortColumnEnum,
  TFilter,
  TDataSource extends object,
> = {
  useQuery: UseQueryHook<TQuery, TSortColumnEnum, TFilter>;
  filter: TFilter;
  dataToRows?: (data: TQuery | undefined) => TDataSource[];
};

export const useServerPaginatedTable = <
  TQuery extends ServerPaginatedQuery,
  TSortColumnEnum,
  TFilter,
  TDataSource extends object,
>({
  useQuery,
  filter,
  dataToRows,
}: UseServerPaginatedTableOptions<
  TQuery,
  TSortColumnEnum,
  TFilter,
  TDataSource
>) => {
  const [paginatedInput, onPaginatedTableViewChange] =
    useState<PaginatedTableView<TSortColumnEnum>>();

  const variables = {
    input: {
      filter,
      ...paginatedInput!,
    },
  } as ServerPaginatedQueryVariables<TSortColumnEnum, TFilter>;

  const [{ data, fetching }] = useQuery({
    variables,
    requestPolicy: 'network-only',
    pause: !paginatedInput,
  });

  const dataSource = (dataToRows ? dataToRows(data) : data) as TDataSource[];

  return {
    data,
    dataSource,
    loading: fetching || !paginatedInput,
    onViewChange: onPaginatedTableViewChange,
    totalRecords:
      getQueryData<ServerPaginatedQueryPage<any>>(data)?.totalRecords,
    ...paginatedInput,
  };
};
