import classNames from 'classnames';
import { Fragment } from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgLocation } from 'xo/svg/svg-location';
import { SvgTick } from 'xo/svg/svg-tick';
import { SvgWarning } from 'xo/svg/svg-warning';
import { FormConfigQuestionMostRecentVisitModel } from '../../models/visitor-log-models';
import { IconText } from '../components/icon-text';
import { StartToEndDateTime } from '../components/start-to-end-date-time';
import { pluralize } from '../utils';
import {
  MostRecentVisitSiteModel,
  getMostRecentVisitsSummary,
} from './most-recent-visits-utils';

const MostRecentVisitSite = ({
  siteName,
  entryExitDateTimes,
  quarantineNights,
  className,
}: MostRecentVisitSiteModel & { className?: string }) => (
  <IconText
    className={classNames('font-semibold', className)}
    icon={<SvgLocation fill={colors.black} />}
    extra={
      <div className="text-base">
        {entryExitDateTimes.map(({ entryDateTime, exitDateTime }, i) => (
          <Fragment key={entryDateTime.format()}>
            <div className={classNames({ 'mt-2': i === 0, 'mt-3': i > 0 })}>
              <StartToEndDateTime start={entryDateTime} end={exitDateTime} />
            </div>

            {typeof quarantineNights === 'number' && (
              <div className="mt-2 font-semibold text-red-800">
                {quarantineNights} NIGHT GAP REQUIRED
              </div>
            )}
          </Fragment>
        ))}
      </div>
    }
  >
    {siteName}
  </IconText>
);

export interface MostRecentVisitsProps {
  mostRecentVisits: FormConfigQuestionMostRecentVisitModel[];
  border?: boolean;
}

export const MostRecentVisits = ({
  mostRecentVisits,
  border = true,
}: MostRecentVisitsProps) => {
  const {
    overallCompliant,
    compliantSites: compliantVisits,
    nonCompliantSites: nonCompliantVisits,
  } = getMostRecentVisitsSummary(mostRecentVisits);

  const recordCount = (v: MostRecentVisitSiteModel[]) =>
    v.flatMap(v => v.entryExitDateTimes).length;

  const titleVisits = overallCompliant ? compliantVisits : nonCompliantVisits;
  const titleVisitCount = recordCount(titleVisits);
  const title = `${titleVisitCount} ${
    !overallCompliant ? 'non-' : ''
  }compliant ${pluralize(titleVisitCount, 'record')}`;
  const subTitle = overallCompliant
    ? 'No quarantine required'
    : 'Quarantine periods not met';

  const firstSectionSites = nonCompliantVisits.length
    ? nonCompliantVisits
    : compliantVisits;
  const secondSectionSites = nonCompliantVisits.length ? compliantVisits : [];
  const secondSectionCount = recordCount(secondSectionSites);

  const renderSites = (sites: MostRecentVisitSiteModel[]) => (
    <div className="px-2 py-4">
      {sites.map((s, i) => (
        <MostRecentVisitSite
          className={classNames({ 'mt-4': i > 0 })}
          key={s.siteName}
          {...s}
        />
      ))}
    </div>
  );

  return (
    <div
      className={classNames('rounded-lg', {
        'border border-solid border-grey-400':
          // Always have a border when low-risk
          border || (compliantVisits.length && !nonCompliantVisits.length),
      })}
    >
      <div
        className={classNames('rounded-t-lg px-2 py-4', {
          'bg-green-100': overallCompliant,
          'bg-red-100': !overallCompliant,
        })}
      >
        <IconText
          className="font-semibold"
          icon={
            overallCompliant ? (
              <SvgTick fill={colors.green[700]} />
            ) : (
              <SvgWarning fill={colors.red[700]} />
            )
          }
          size="xl"
          extra={<div className="mt-1 text-base">{subTitle}</div>}
        >
          {title}
        </IconText>
      </div>
      {renderSites(firstSectionSites)}

      {secondSectionSites.length > 0 && (
        <div>
          <div className="bg-grey-50 px-2 py-4">
            <IconText
              className="font-semibold"
              icon={<SvgTick fill={colors.grey[800]} />}
              extra={<div className="text-base">No quarantine required</div>}
              size="xl"
            >
              {secondSectionCount} other compliant{' '}
              {pluralize(secondSectionCount, 'record')}
            </IconText>
          </div>
          {renderSites(secondSectionSites)}
        </div>
      )}
    </div>
  );
};
