import { isString } from 'lodash';
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { Skeleton } from 'xo/components/skeleton';
import { Box, BoxProps, Text } from 'xo/core';
import { Color, FontSize } from 'xo/styles/restyle/theme';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgMinus } from 'xo/svg/svg-minus';
import { SvgTick } from 'xo/svg/svg-tick';

export interface CheckboxProps extends BoxProps {
  label?: string | JSX.Element;
  subLabel?: string | JSX.Element;
  value?: boolean;
  indeterminate?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  labelLeft?: boolean;
  loading?: boolean;
  color?: Color;
  fontSize?: FontSize;
}

export const Checkbox = ({
  label,
  subLabel,
  value,
  indeterminate,
  onChange,
  disabled,
  labelLeft,
  loading,
  color,
  fontSize = 'lg',
  ...rest
}: CheckboxProps) => {
  const onPress = useCallback(
    () => onChange && onChange(!value),
    [onChange, value],
  );

  const renderLabel = (
    <Box flex={1} direction="row">
      {isString(label) ? (
        <Text fontSize={fontSize} color={color}>
          {label}
        </Text>
      ) : (
        label
      )}
    </Box>
  );

  const renderSubLabel = (
    <Box flex={1} direction="row" flexWrap="wrap">
      {isString(subLabel) ? (
        <Text fontSize={'sm'} color={color}>
          {subLabel}
        </Text>
      ) : (
        subLabel
      )}
    </Box>
  );

  return (
    <Pressable disabled={disabled} onPress={onPress}>
      {({ pressed }) => (
        <Box
          direction="row"
          align="flex-start"
          justify="space-between"
          gap={'2'}
          {...rest}
        >
          {labelLeft && (
            <Box rowGap="2" flex={1}>
              {renderLabel}
              {subLabel && renderSubLabel}
            </Box>
          )}

          {loading ? (
            <Skeleton w="6" h="6" />
          ) : (
            <Box
              w="6"
              h="6"
              bg={
                value && disabled
                  ? 'grey.500'
                  : value && !disabled && !pressed
                    ? 'blue.400'
                    : pressed
                      ? 'blue.600'
                      : 'white'
              }
              borderColor={
                disabled ? 'grey.500' : pressed ? 'blue.600' : 'blue.400'
              }
              rounded="sm"
              borderStyle="solid"
              borderWidth="2"
              align="center"
              justify="center"
            >
              {indeterminate ? (
                <SvgMinus fill={colors.white} />
              ) : (
                <SvgTick fill={value ? colors.white : colors.transparent} />
              )}
            </Box>
          )}
          {!labelLeft && (
            <Box width="full" rowGap="2">
              {renderLabel}
              {subLabel && renderSubLabel}
            </Box>
          )}
        </Box>
      )}
    </Pressable>
  );
};
