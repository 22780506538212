import qs from 'query-string';
import React from 'react';
import { useParams } from 'react-router-dom';
import { formatIsoDate, formatTimeAndDate } from 'xo/date-utils';
import { ApiTripEventKind } from 'xo/graphql/api/enums/trip-event-kind.generated';
import { useGetTripQuery } from 'xo/graphql/api/get-trip-query.generated';
import { ApiSiteFragment } from 'xo/graphql/api/site-fragment.generated';
import { ApiTripEventFragment } from 'xo/graphql/api/trip-event-fragment.generated';
import { ApiTripFragment } from 'xo/graphql/api/trip-fragment.generated';
import { appRoutes } from 'xo/navigation/web-routes';
import { formatDuration } from 'xo/transport/trip-summary-utils';
import { Header } from '../components/header';
import { Link } from '../components/link';
import { Panel } from '../components/panel';
import { Table, TableColumnProps } from '../components/table';
import { AdminOverrideTripEvent } from './admin-override-trip-event';
import { AdminSiteAirtableIdInput } from './admin-site-airtable-id-input';

const VehicleEventLink: React.FC<{ trip: ApiTripFragment }> = ({ trip }) => {
  const date = formatIsoDate(trip.date);
  const search = qs.stringify(
    { from: date, to: date, vehicles: trip.vehicle.trackedPlates },
    { arrayFormat: 'bracket' },
  );

  return trip.vehicle.trackedPlates.length > 0 ? (
    <Link
      to={{ pathname: appRoutes.admin.vehicleEvents, search }}
      className="mt-2"
    >
      View all vehicle events for {trip.vehicle.trackedPlates.join(', ')} on
      this trip's date
    </Link>
  ) : (
    <div className="mt-2">
      Cannot view vehicle events, because this trip has no licence plates with
      GPS tracking
    </div>
  );
};

export const AdminTransportTripDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [{ data, fetching }] = useGetTripQuery({
    variables: { id },
    requestPolicy: 'cache-only',
  });

  const columns: TableColumnProps<ApiTripEventFragment>[] = [
    {
      title: 'KIND',
      dataIndex: 'kind',
      render: (_: ApiTripEventKind, event: ApiTripEventFragment) => (
        <AdminOverrideTripEvent event={event} />
      ),
      width: '15%',
    },
    {
      title: 'ACTUAL START',
      dataIndex: 'actualStartTime',
      render: formatTimeAndDate,
      width: '10%',
    },
    {
      title: 'ACTUAL END',
      dataIndex: 'actualEndTime',
      render: formatTimeAndDate,
      width: '10%',
    },
    {
      title: 'DURATION',
      dataIndex: 'id',
      render: (_: any, event: ApiTripEventFragment) => {
        const duration =
          event.actualEndTime && event.actualStartTime
            ? formatDuration({
                compact: true,
                start: event.actualStartTime,
                end: event.actualEndTime,
              })
            : null;
        return duration?.desc;
      },
      width: '10%',
    },
    {
      title: 'DUE START',
      dataIndex: 'dueStartTime',
      render: formatTimeAndDate,
      width: '10%',
    },
    {
      title: 'PREDICTED START',
      dataIndex: 'predictedStartTime',
      render: formatTimeAndDate,
      width: '10%',
    },
    {
      title: 'RISK',
      dataIndex: 'riskLevel',
      width: '5%',
    },
    {
      title: 'SITE',
      dataIndex: 'site',
      render: (site?: ApiSiteFragment) =>
        site ? `${site.name} (${site.kind})` : null,
      width: '15%',
    },
    {
      title: 'AIRTABLE ID',
      dataIndex: 'site',
      render: (site?: ApiSiteFragment) =>
        site ? <AdminSiteAirtableIdInput key={site.id} site={site} /> : null,
      width: '15%',
    },
  ];

  return (
    <Panel className="my-4">
      <Header level={2} underline={true} className="mb-4">
        Trip details admin
      </Header>
      <Table
        columns={columns}
        dataSource={data?.trip?.events ?? []}
        loading={fetching}
        pagination={false}
      />
      {data?.trip && <VehicleEventLink trip={data.trip} />}
    </Panel>
  );
};
