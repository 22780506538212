import { Collapse } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCaret } from 'xo/svg/svg-caret';
import { useSet } from '../../hooks/shared-hooks';
import './accordion.overrides.css';

export interface AccordionPanel {
  key: string;
  header: React.ReactNode;
  content: React.ReactNode;
  defaultActive?: boolean;
}

export interface AccordionProps {
  panels: AccordionPanel[];
  single?: boolean;
  disabled?: boolean;
  onChange?: (key?: string | string[]) => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  panels,
  single,
  disabled,
  onChange,
}) => {
  const [activeKeySet, { add, clear }] = useSet<string>(
    panels.filter(p => p.defaultActive).map(p => p.key),
  );
  const activeKeys = useMemo(() => Array.from(activeKeySet), [activeKeySet]);

  const onChangeCb = useCallback(
    (key?: string | string[]) => {
      clear();
      if (Array.isArray(key)) {
        key.forEach(k => add(k));
      } else if (key) {
        add(key);
      }

      onChange && onChange(key);
    },
    [clear, add, onChange],
  );

  return (
    <Collapse
      className="xo-ant-accordion"
      accordion={single}
      onChange={onChangeCb}
      activeKey={activeKeys}
    >
      {panels.map(({ key, header, content }) => (
        <Collapse.Panel
          key={key}
          showArrow={false}
          className="xo-ant-accordion-item"
          collapsible={disabled ? 'disabled' : undefined}
          header={
            <div className="flex w-full items-center justify-between text-lg font-medium text-blue-600 hover:text-blue-400">
              {header}
              <span className={classNames({ invisible: disabled })}>
                <SvgCaret
                  direction={activeKeySet.has(key) ? 'u' : 'd'}
                  fill={colors.grey[500]}
                  scale={1.5}
                />
              </span>
            </div>
          }
        >
          {content}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
