import { partition } from 'lodash';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ApiGetCurrentUserQuery,
  GetCurrentUserDocument,
} from 'xo/graphql/api/get-current-user-query.generated';
import { useClient } from 'xo/graphql/urql-provider';
import logger from 'xo/logger';
import {
  appRoutes,
  arrivalDashboardRoutes,
  consignmentNoteRoutes,
  transportRoutes,
} from 'xo/navigation/web-routes';
import { FormKind } from '../../api-models';
import { LoginRedirectState } from '../../hooks/auth-hooks';
import {
  getUserModulePermissions,
  getUserShouldNotSeeSchedule,
} from '../../hooks/auth-utils';
import { useGetVisitForms } from '../../hooks/visit-network-hooks';
import { FormResponse } from '../../models/visitor-log-models';
import {
  HostReviewGroup,
  applyHostResponses,
  compileQuestionnaireResponses,
  mostRecentVisitQuestionIds,
} from '../components/question-response-helpers';

export const useRedirectOnLogin = () => {
  const client = useClient();
  const location = useLocation<LoginRedirectState>();
  const history = useHistory();

  return useCallback(async () => {
    const result = await client.query<ApiGetCurrentUserQuery>(
      GetCurrentUserDocument,
      {},
    );
    const user = result.data?.me;

    if (!user) {
      logger.error('failed to load user on login');
      return;
    }

    const {
      visitorPermission,
      transportSchedulingPermission,
      consignmentNotePermission,
      arrivalDashboardPermission,
    } = getUserModulePermissions(user);

    const defaultRoute =
      visitorPermission && getUserShouldNotSeeSchedule(user)
        ? appRoutes.reporting.visitorLog
        : visitorPermission
          ? appRoutes.schedule
          : consignmentNotePermission
            ? consignmentNoteRoutes.root
            : transportSchedulingPermission
              ? transportRoutes.root
              : arrivalDashboardPermission
                ? arrivalDashboardRoutes.root
                : appRoutes.settings.userDetails(user.id);

    const redirectOnLoginPath =
      !location.state?.redirect ||
      location.state?.redirect.pathname.includes('login')
        ? defaultRoute
        : location.state?.redirect;

    history.push(redirectOnLoginPath);
  }, [history, location, client]);
};

const getReviewGroups = ({
  visitorForm,
  hostForm,
}: {
  visitorForm?: FormResponse;
  hostForm?: FormResponse;
}) =>
  visitorForm?.config && visitorForm?.riskAssessment
    ? applyHostResponses({
        config: visitorForm.config,
        groups: compileQuestionnaireResponses({
          ...visitorForm,
          mostRecentVisitBorder: false,
        }),
        hostResponses: hostForm?.riskAssessment
          ? hostForm?.responses ?? {}
          : {},
      }).map(g => {
        // Identify most recent visit questions so they're linked
        if (
          g.questions.some(({ questionId }) =>
            mostRecentVisitQuestionIds.has(questionId),
          )
        ) {
          return {
            key: 'most-recent-visit',
            group: g,
          };
        }

        // Need to match up the separate groups so we can render them together
        // Uses the review prompt title as the keys to identify the group, unless its one of the most recent visit questions
        return {
          key: g.reviewPrompt.title,
          group: g,
        };
      })
    : undefined;

export interface CombinedHostReviewGroup {
  early?: HostReviewGroup;
  onsite?: HostReviewGroup;
  key: string;
}

export const resolveHostReviewGroup = (
  kind: FormKind,
  { early, onsite }: CombinedHostReviewGroup,
) => (kind === 'ONSITE_HOST' && onsite ? onsite : early!);

export const resolveHostReviewGroups = (
  kind: FormKind,
  groups: CombinedHostReviewGroup[],
): HostReviewGroup[] =>
  groups.map(group => resolveHostReviewGroup(kind, group)).filter(g => g);

export const useCombinedVisitForms = ({
  visitId,
  cache,
}: {
  visitId?: string;
  cache?: boolean;
}) => {
  const {
    isLoading: loading,
    data: formsMap,
    refetch,
  } = useGetVisitForms({
    id: visitId,
    cache,
  });

  const earlyReviewGroups = getReviewGroups({
    visitorForm: formsMap?.EARLY,
    hostForm: formsMap?.EARLY_HOST,
  });

  const onsiteReviewGroups = getReviewGroups({
    visitorForm: formsMap?.ONSITE,
    hostForm: formsMap?.ONSITE_HOST,
  });

  const keys = Array.from(
    new Set(
      (earlyReviewGroups?.map(g => g.key) ?? []).concat(
        onsiteReviewGroups?.map(g => g.key) ?? [],
      ),
    ),
  );
  const earlyMap = new Map(earlyReviewGroups?.map(g => [g.key, g.group]));
  const onsiteMap = new Map(onsiteReviewGroups?.map(g => [g.key, g.group]));

  const combinedGroups: CombinedHostReviewGroup[] = keys.map(key => ({
    early: earlyMap.get(key),
    onsite: onsiteMap.get(key),
    key,
  }));

  const [nonLowRiskGroups, lowRiskGroups] = partition(
    combinedGroups,
    ({ early, onsite }) =>
      early?.questions.some(q => q.risk !== 'LOW') ||
      onsite?.questions.some(q => q.risk !== 'LOW'),
  );

  return {
    nonLowRiskGroups,
    lowRiskGroups,
    formsMap,
    loading,
    refetch,
  };
};
