import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { SUPPORT_PHONE } from 'xo/constants';
import { Box } from 'xo/core';
import { LogoVariant } from 'xo/models';
import { SvgExoFlareLogo } from 'xo/svg/svg-exoflare-logo';
import { toId } from 'xo/utils/string-utils';
import { useScrollToFirstError } from '../../hooks/form-hooks';
import { VisitModel } from '../../models/visitor-log-models';
import { checkedValidator } from '../forms/validators';
import { Checkbox } from './checkbox';
import { ContactPanelDetails } from './contact-panel-details';
import { PageTitle } from './page-title';
import { TelLink } from './tel-link';

const AgreeCheckbox: React.FC<{
  name: string;
  label: string;
  path: string;
}> = ({ name, label, path }) => (
  <Form.Item
    name={name}
    rules={[checkedValidator(`Please agree to our ${label} before continuing`)]}
    className="flex items-center"
    valuePropName="checked"
  >
    <Checkbox boldChecked={false}>
      <span className="mt-1 text-base">
        I have read and agree to ExoFlare's{' '}
        <a
          className="text-blue-600 underline"
          href={`https://www.exoflare.io/${path}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      </span>
    </Checkbox>
  </Form.Item>
);

const title = 'Terms and conditions';

export interface ClickthroughAgreementProps {
  visit?: VisitModel;
  form?: FormInstance<any>;
}

export const ClickthroughAgreement: React.FC<ClickthroughAgreementProps> = ({
  form,
  visit,
}) => {
  const onScrollToError = useScrollToFirstError(form);

  return (
    <>
      <Box mt={4} mb={5}>
        <SvgExoFlareLogo variant={LogoVariant.BlackOrange} />
      </Box>
      <PageTitle title="Terms and conditions" />
      <h1 className="my-4 text-2xl font-medium" id={toId(title)}>
        {title}
      </h1>
      <div className="text-xl">
        <p className="mt-4">
          ExoFlare app is a risk assessment service that helps organisations
          manage biosecurity. This app will assist you with your requirements to
          assure the health of animals on farms and elsewhere.
        </p>
        <p className="my-4">
          To do this we collect some information about your visit and share this
          with the site you are visiting. This information may include contact,
          personal, health and device-related information. This information is
          collected to provide, manage and administer the ExoFlare service.
        </p>
        <p className="mb-8">
          Please read the full Terms of Use and Privacy Policy.
        </p>

        <div>
          <Form
            initialValues={{ terms: false, privacy: false }}
            form={form}
            onFinishFailed={onScrollToError}
          >
            <AgreeCheckbox
              name="terms"
              label="Terms of Use"
              path="terms-of-use"
            />
            <AgreeCheckbox
              name="privacy"
              label="Privacy Policy"
              path="privacy-policy"
            />
          </Form>

          <p className="my-8">
            {visit ? (
              <ContactPanelDetails type="host" {...visit.host} />
            ) : (
              <>
                Any questions? Contact ExoFlare on{' '}
                <TelLink phoneNumber={SUPPORT_PHONE} />.
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};
