// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
export type ApiScheduledMovementStockEditabilityFragment = {
  __typename?: 'ScheduledMovementStockEditability';
  actualLivestockQuantity: boolean;
  actualTattoo: boolean;
  killDay: boolean;
  kind: boolean;
  notes: boolean;
  scheduledLivestockQuantity: boolean;
  operatorOther: boolean;
  producerOther: boolean;
};

export const ScheduledMovementStockEditabilityFragmentDoc = gql`
  fragment ScheduledMovementStockEditability on ScheduledMovementStockEditability {
    __typename
    actualLivestockQuantity
    actualTattoo
    killDay
    kind
    notes
    operatorOther: operator
    producerOther: producer
    scheduledLivestockQuantity
  }
`;
