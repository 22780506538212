import classNames from 'classnames';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { getTodayYesterdayAppender } from 'xo/date-utils';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCalendarEvent } from 'xo/svg/svg-calendar-event';
import { SvgLocation } from 'xo/svg/svg-location';
import { SvgTick } from 'xo/svg/svg-tick';
import { SvgWarning } from 'xo/svg/svg-warning';
import { FormQuestionAnswerSiteEntry } from '../../api-models';
import { useSiteMap } from '../../hooks/shared-hooks';
import { IconText } from '../components/icon-text';
import {
  compliantToPolicy,
  notCompliantToPolicy,
} from '../people-office/visitor-log-constants';

const SiteEntry = ({
  className,
  entry,
}: {
  className?: string;
  entry: FormQuestionAnswerSiteEntry;
}) => {
  const siteMap = useSiteMap();
  const internal = siteMap.has(entry.value);
  const showNightsMessage =
    !entry.compliant && typeof entry.quarantineNights === 'number';
  const complianceMessage = entry.compliant
    ? compliantToPolicy({ internal })
    : notCompliantToPolicy({ internal });
  const datetime = dayjs(entry.datetime);

  return (
    <div className={className}>
      <IconText icon={<SvgLocation fill={colors.black} />} wrap={true}>
        {siteMap.get(entry.value)?.name ?? entry.details}
      </IconText>
      <IconText
        className="my-2"
        icon={<SvgCalendarEvent fill={colors.black} />}
        wrap={true}
      >
        <span data-e2e-hide={true}>
          {datetime.format('h:mma D/MM/YY')}
          {getTodayYesterdayAppender(datetime)}
        </span>
      </IconText>
      <IconText
        className="font-semibold"
        icon={
          entry.compliant ? (
            <SvgTick fill={colors.green[500]} />
          ) : (
            <SvgWarning fill={colors.red[700]} />
          )
        }
        extra={
          <div
            className={classNames('mt-1 text-base', {
              'font-semibold text-red-800': showNightsMessage,
            })}
          >
            {showNightsMessage
              ? `${entry.quarantineNights} NIGHT GAP REQUIRED`
              : 'No quarantine required'}
          </div>
        }
      >
        {complianceMessage}
      </IconText>
    </div>
  );
};

export interface SiteEntriesProps {
  className?: string;
  entries: FormQuestionAnswerSiteEntry[];
}

export const SiteEntries = ({ className, entries }: SiteEntriesProps) => {
  const sortedEntries = sortBy(entries, e => dayjs(e.datetime).toDate());

  return (
    <div className={className}>
      {sortedEntries.map(entry => (
        <SiteEntry
          className="mt-6"
          key={dayjs(entry.datetime).format()}
          entry={entry}
        />
      ))}
    </div>
  );
};
