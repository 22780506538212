import qs from 'querystring';
import {
  minPhoneLengthByCountryAbbr,
  SupportedPhoneCountryAbbr,
} from './constants';
import logger from './logger';

export const EMAIL_REGEX = /^.+@.+\..+$/;
export const isValidEmail = (value: string) => EMAIL_REGEX.test(value);

export interface IsValidPhoneNumberParams {
  phone: string | undefined;
  countryAbbr: SupportedPhoneCountryAbbr;
  requireMobile: boolean;
  onError: (error: string) => void;
  origin?: string;
}

export const isValidPhoneNumber = async ({
  phone,
  countryAbbr,
  requireMobile,
  origin,
  onError,
}: IsValidPhoneNumberParams) => {
  try {
    // early out if the string isn't the right length
    if (
      !phone ||
      phone.replace(/ /g, '').length < minPhoneLengthByCountryAbbr[countryAbbr]
    ) {
      return false;
    }

    // FIXME Switch to GraphQL when phone validation is available there
    const response = await fetch(
      `${origin ?? ''}/api/v3-open/validation/phone?${qs.stringify({
        phone,
        country: countryAbbr,
        requireMobile,
      })}`,
    );

    const data = await response.json();

    return data.valid;
  } catch (e) {
    logger.error(e);
    onError(`Sorry, a server error prevented phone validation`);
    return false;
  }
};
