import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiSiteFragment } from 'xo/graphql/api/site-fragment.generated';

export const hasApiModuleAccess = (
  site: Pick<ApiSiteFragment, 'organisation' | 'modules'>,
  module: ApiModule | undefined,
) =>
  !module ||
  (site.organisation.modules.includes(module) && site.modules.includes(module));

export const hasApiVisitorModuleAccess = (
  site: Pick<ApiSiteFragment, 'organisation' | 'modules'>,
) => hasApiModuleAccess(site, ApiModule.Visitor);

export const hasApiTransportModuleAccess = (
  site: Pick<ApiSiteFragment, 'organisation' | 'modules'>,
) => hasApiModuleAccess(site, ApiModule.Transport);
