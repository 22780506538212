import classNames from 'classnames';
import React from 'react';

export const TooltipButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, className, ...rest }) => (
  <button
    className={classNames(
      className,
      'rounded-lg px-2 py-1 text-sm font-bold text-white shadow-lg print:hidden',
    )}
    {...rest}
  >
    {children}
  </button>
);
