import { Pressable } from 'xo/components/pressable';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgEye } from 'xo/svg/svg-eye';
import { SvgEyeInvisible } from 'xo/svg/svg-eye-invisible';

interface VisibilityToggleProps {
  visible: boolean;
  // leaving as optional in order to allow indirectly passing the prop from the parent
  onClick?: () => void;
}

export const VisibilityToggle = ({
  visible,
  onClick,
}: VisibilityToggleProps) => (
  <Pressable
    onPress={onClick ?? (() => {})}
    accessibilityLabel={visible ? 'hide' : 'show'}
    style={{ height: 18 }}
  >
    {({ hovered }) => {
      const color = hovered ? colors.grey[800] : colors.grey[500];
      return visible ? (
        <SvgEye fill={color} />
      ) : (
        <SvgEyeInvisible fill={color} />
      );
    }}
  </Pressable>
);
