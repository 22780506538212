import { useCallback, useEffect } from 'react';
import { getAuthToken } from 'xo/auth-utils';
import { shallow } from 'zustand/shallow';
import { useSignOutListener } from './use-signout-listener';
import { AuthStatus, useUserStore } from './user-store';

export const useIsLoggedIn = () =>
  useUserStore(state => state.authStatus) === AuthStatus.Authenticated;

export const useLoadAuthStatus = () => {
  const [authStatus, setAuthStatus] = useUserStore(
    state => [state.authStatus, state.setAuthStatus],
    shallow,
  );

  const onLoad = useCallback(async () => {
    if (authStatus === AuthStatus.Loading) {
      const authToken = await getAuthToken();

      setAuthStatus(
        authToken ? AuthStatus.Authenticated : AuthStatus.Unauthenticated,
      );
    }
  }, [authStatus, setAuthStatus]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useSignOutListener(
    useCallback(() => {
      setAuthStatus(AuthStatus.Unauthenticated);
    }, [setAuthStatus]),
  );

  return authStatus;
};
