import { Amplify } from 'aws-amplify';
import {
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchMFAPreference,
  getCurrentUser,
  resetPassword,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from 'aws-amplify/auth';
import { ausCountryCodeIfPhone } from './account-utils';

export const getAuthToken = async () => {
  try {
    const session = await fetchAuthSession();
    const token = session?.tokens?.idToken?.toString();
    return token || null;
  } catch {}
  return null;
};

export const getMfaUri = async ({
  stage,
  sharedSecret,
}: {
  stage: string;
  sharedSecret: string;
}) => {
  const [session, user] = await Promise.all([
    fetchAuthSession(),
    getCurrentUser(),
  ]);
  const uri = `otpauth://totp/${stage}-ExoFlare:${user.username}?secret=${sharedSecret}&issuer=${session?.tokens?.idToken?.payload?.iss}`;
  return uri;
};

interface SignIn {
  username: string;
  password?: string;
  authFlowType?: 'USER_PASSWORD_AUTH' | 'CUSTOM_WITHOUT_SRP';
}

// amplify can throw if we're already signed in so just make sure we're signed out first
export const signIn = async ({
  username,
  password,
  authFlowType = 'USER_PASSWORD_AUTH',
}: SignIn) => {
  await cognitoSignOut();

  return await cognitoSignIn({
    username: ausCountryCodeIfPhone(username),
    password,
    options: { authFlowType },
  });
};

export const configureAuth = ({
  poolId,
  clientId,
}: {
  poolId: string;
  clientId: string;
}) =>
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: poolId,
        userPoolClientId: clientId,
      },
    },
  });

export const signOut = () => cognitoSignOut();
export const completeNewPassword = async ({
  username,
  tempPassword,
  newPassword,
}: {
  username: string;
  tempPassword: string;
  newPassword: string;
}) => {
  // if user refreshed the page, we'll need to reinitiate sign in flow
  const response = await signIn({
    username,
    password: tempPassword,
    authFlowType: 'USER_PASSWORD_AUTH',
  });

  if (
    response.nextStep.signInStep !==
    'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
  ) {
    throw new Error(
      `Unexpected set password next step ${response.nextStep.signInStep}`,
    );
  }
  await confirmSignIn({ challengeResponse: newPassword });
};
export const forgotPasswordSubmit = (
  username: string,
  tempPassword: string,
  newPassword: string,
) =>
  confirmResetPassword({
    username: ausCountryCodeIfPhone(username),
    confirmationCode: tempPassword,
    newPassword,
  });
export const forgotPassword = (username: string) =>
  resetPassword({ username: ausCountryCodeIfPhone(username) });

// https://github.com/aws-amplify/amplify-js/pull/566
export const initiateAuth = async (username: string) =>
  await signIn({
    username,
    authFlowType: 'CUSTOM_WITHOUT_SRP',
  });

export const respondToAuthChallenge = async (response: string) => {
  return await confirmSignIn({ challengeResponse: response });
};

export const startMfaSetup = async () => {
  const result = await setUpTOTP();
  return result.sharedSecret;
};

export const finishMfaSetup = async (code: string) => {
  await verifyTOTPSetup({ code });
  await updateMFAPreference({ totp: 'ENABLED' });
  return await getMfaStatus();
};

export const getCognitoUser = async () => await getCurrentUser();

export const confirmMfaCode = async (code: string) => {
  await confirmSignIn({ challengeResponse: code });
};

export const getMfaStatus = async () => {
  const mfa = await fetchMFAPreference();
  return mfa.enabled?.includes('TOTP');
};

export const removeMfa = async () => {
  await updateMFAPreference({ totp: 'DISABLED' });
  return await getMfaStatus();
};
