import classNames from 'classnames';
import { motion } from 'framer-motion';
import { LocationDescriptor } from 'history';
import { isString } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from 'xo/components/loading-spinner';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgArrow } from 'xo/svg/svg-arrow';
import { Button } from './button';
import { Container } from './container';

export const enterExitAnim = {
  initial: { translateY: 50 },
  animate: { translateY: 0 },
  exit: { translateY: 50 },
  transition: {
    duration: 0.15,
    ease: 'easeInOut',
  },
};

interface FooterButtonProps {
  onClick?: () => void;
  to?: LocationDescriptor;
  loading?: boolean;
  label?: string | JSX.Element;
}

export interface MiddleFooterButtonProps extends FooterButtonProps {
  active?: boolean;
}

export interface SideFooterButtonProps extends FooterButtonProps {
  icon?: JSX.Element;
  warning?: boolean;
  hidden?: boolean;
}

export interface FooterButtonsProps {
  middle?: MiddleFooterButtonProps;
  left?: SideFooterButtonProps;
  right?: SideFooterButtonProps;
}

const SideButton: React.FC<
  SideFooterButtonProps & { direction: 'l' | 'r' }
> = ({ hidden, icon, warning, onClick, loading, label, direction, to }) => (
  <Button
    shape="squircle"
    shadow={true}
    icon={React.cloneElement(icon || <SvgArrow direction={direction} />, {
      fill: warning ? colors.red[700] : undefined,
    })}
    iconRight={direction === 'r'}
    className={classNames(
      'min-w-36 transition-all duration-200 ease-in sm:min-w-40',
      {
        'opacity-0': hidden,
      },
    )}
    onClick={onClick}
    loading={loading}
    alert={warning}
    to={to}
    data-testid={`footer-${direction}`}
    aria-label={isString(label) ? label : undefined}
  >
    {label}
  </Button>
);

const middleClassName =
  'uppercase text-white font-medium text-base flex justify-center items-center h-14 sm:h-10 w-full bg-blue-400 shadow-t-md';

export const FooterButtons: React.FC<FooterButtonsProps> = ({
  middle,
  left,
  right,
}) => {
  return (
    <motion.div key={middle?.active?.toString()} {...enterExitAnim}>
      <Container>
        <div className="flex h-20 items-center justify-between sm:h-16">
          {middle?.active ? (
            <>
              {middle?.to ? (
                <Link
                  className={middleClassName}
                  to={middle.to}
                  style={{ textDecoration: 'none', color: colors.white }}
                >
                  {middle.label}
                </Link>
              ) : middle ? (
                <button
                  className={middleClassName}
                  onClick={middle.onClick}
                  disabled={middle.loading}
                  data-testid="footer-m"
                >
                  {middle.loading ? (
                    <LoadingSpinner color={colors.blue[200]} size="large" />
                  ) : (
                    middle.label
                  )}
                </button>
              ) : null}
            </>
          ) : (
            <div className="flex w-full items-center justify-between py-3">
              <SideButton {...(left ?? { hidden: true })} direction="l" />
              <SideButton {...(right ?? { hidden: true })} direction="r" />
            </div>
          )}
        </div>
      </Container>
    </motion.div>
  );
};
