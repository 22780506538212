import { OrgFeatureFlag, UserRole } from 'xo/constants';
import { ApiCurrentUserFragment } from 'xo/graphql/api/current-user-fragment.generated';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiUserFragment } from 'xo/graphql/api/user-fragment.generated';

// TODO Move this to the xo package

export type UserPermissions = ReturnType<typeof getPermissions>;

export const isOrgAdmin = (user: Pick<ApiUserFragment, 'roles'>) =>
  !!user?.roles.some(r => r.kind === UserRole.OrgAdmin);

export const getPermissions = (
  user: Pick<
    ApiCurrentUserFragment | ApiUserFragment,
    | 'roles'
    | 'organisation'
    | 'airtableDriverId'
    | 'hostAtSites'
    | 'transportAtSites'
  > | null,
) => {
  const roles = new Set(user?.roles?.map(r => r.kind));
  const orgModules = new Set(user?.organisation?.modules);
  const orgFeatures = new Set(user?.organisation?.features);
  const isOrgAdmin = roles.has(UserRole.OrgAdmin);
  const isAirtableDriver = !!user?.airtableDriverId;

  const visitorModule =
    orgModules.has(ApiModule.Visitor) &&
    (!!user?.hostAtSites?.length || isOrgAdmin) &&
    !isAirtableDriver;

  const transportScheduling =
    orgFeatures.has(OrgFeatureFlag.TransportScheduling) &&
    (!!user?.transportAtSites?.length || isOrgAdmin) &&
    !isAirtableDriver;
  const consignmentNote = orgFeatures.has(OrgFeatureFlag.ConsignmentNotes);
  const arrivalDashboard = orgFeatures.has(OrgFeatureFlag.ArrivalDashboard);

  return {
    isOrgAdmin,
    visitor: visitorModule
      ? {
          visitorPrebooking: orgFeatures.has(OrgFeatureFlag.VisitorPrebooking),
          deliveryPrebooking: orgFeatures.has(
            OrgFeatureFlag.DeliveryPrebooking,
          ),
        }
      : undefined,
    transport:
      transportScheduling || consignmentNote || arrivalDashboard
        ? {
            transportScheduling,
            consignmentNote,
            arrivalDashboard: arrivalDashboard
              ? {
                  gatehouse: roles.has(UserRole.OrgGatehouse),
                  lairage: roles.has(UserRole.OrgLairage),
                  planner: roles.has(UserRole.OrgPlanner),
                  uploadArrivalSpreadsheet:
                    isOrgAdmin || roles.has(UserRole.OrgPlanner),
                }
              : undefined,
          }
        : undefined,
  };
};
