import { ApiCommodityKind } from 'xo/graphql/api/enums/commodity-kind.generated';
import { ApiInternalCommodityKind } from 'xo/graphql/api/enums/internal-commodity-kind.generated';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiQuestionnaireFamilyKind } from 'xo/graphql/api/enums/questionnaire-family-kind.generated';
import { ApiSiteActiveStatus } from 'xo/graphql/api/enums/site-active-status.generated';
import { ApiTransportNotificationKind } from 'xo/graphql/api/enums/transport-notification-kind.generated';

import gql from 'graphql-tag';
import {
  ApiOrganisationSummaryFragment,
  OrganisationSummaryFragmentDoc,
} from './organisation-summary-fragment.generated';
import { ApiPlaceFragment, PlaceFragmentDoc } from './place-fragment.generated';
import {
  ApiPositionFragment,
  PositionFragmentDoc,
} from './position-fragment.generated';
import {
  ApiScheduledMovementEditabilityFragment,
  ScheduledMovementEditabilityFragmentDoc,
} from './scheduled-movement-editability-fragment.generated';
import {
  ApiSettingsSiteUserSummaryFragment,
  SettingsSiteUserSummaryFragmentDoc,
} from './settings-site-user-summary-fragment.generated';
import {
  ApiSiteRequiredActionsFragment,
  SiteRequiredActionsFragmentDoc,
} from './site-required-actions-fragment.generated';
import {
  ApiSiteSummaryFragment,
  SiteSummaryFragmentDoc,
} from './site-summary-fragment.generated';
export type ApiSettingsSiteFragment = {
  __typename?: 'Site';
  airtableId?: string;
  modules: Array<ApiModule>;
  commodities: Array<ApiCommodityKind>;
  commoditiesOther?: string;
  allCommoditiesOnSite: Array<ApiInternalCommodityKind>;
  propertyIdentificationCode?: string;
  defaultEntryMessage: string;
  useDefaultEntryMessage: boolean;
  transportNotificationKinds: Array<ApiTransportNotificationKind>;
  timezone: string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  countryCode?: string;
  deleted: boolean;
  activeStatus: ApiSiteActiveStatus;
  exoflareNotes?: string;
  questionnaireFamilyKind?: ApiQuestionnaireFamilyKind;
  maxQuarantineAsDays: number;
  maxQuarantineAsHours: number;
  maxQuarantineNights: number;
  isCheckinAllowed: boolean;
  isCheckinOnly: boolean;
  isEditable: boolean;
  isTest: boolean;
  position?: { __typename?: 'Position' } & ApiPositionFragment;
  organisation: {
    __typename?: 'Organisation';
  } & ApiOrganisationSummaryFragment;
  place?: { __typename?: 'Place' } & ApiPlaceFragment;
  hosts: Array<{ __typename?: 'User' } & ApiSettingsSiteUserSummaryFragment>;
  primaryContact?: { __typename?: 'User' } & ApiSettingsSiteUserSummaryFragment;
  canCreateScheduledMovement?: {
    __typename?: 'ScheduledMovementEditability';
  } & ApiScheduledMovementEditabilityFragment;
} & ApiSiteSummaryFragment &
  ApiSiteRequiredActionsFragment;

export const SettingsSiteFragmentDoc = gql`
  fragment SettingsSite on Site {
    __typename
    ...SiteSummary
    ...SiteRequiredActions
    airtableId
    modules
    commodities
    commoditiesOther
    allCommoditiesOnSite
    propertyIdentificationCode
    defaultEntryMessage
    useDefaultEntryMessage
    transportNotificationKinds
    timezone
    addressLine1
    addressLine2
    suburb
    state
    postcode
    countryCode
    position {
      ...Position
    }
    deleted
    organisation {
      ...OrganisationSummary
    }
    activeStatus
    exoflareNotes
    place {
      ...Place
    }
    hosts {
      ...SettingsSiteUserSummary
    }
    primaryContact {
      ...SettingsSiteUserSummary
    }
    questionnaireFamilyKind
    canCreateScheduledMovement {
      ...ScheduledMovementEditability
    }
    maxQuarantineAsDays
    maxQuarantineAsHours
    maxQuarantineNights
    isCheckinAllowed
    isCheckinOnly
    isEditable
    isTest
  }
  ${SiteSummaryFragmentDoc}
  ${SiteRequiredActionsFragmentDoc}
  ${PositionFragmentDoc}
  ${OrganisationSummaryFragmentDoc}
  ${PlaceFragmentDoc}
  ${SettingsSiteUserSummaryFragmentDoc}
  ${ScheduledMovementEditabilityFragmentDoc}
`;
